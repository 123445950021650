import { useState } from 'react';
import { CreatePolicyMetadataRequest, CreatePolicyMetadataResponse, PolicyMetadata } from 'src/models';
import { CREATE_POLICY_METADATA_API } from 'src/paths';
import { postData } from 'src/clients'; // Import the postData client
import { PostApiParams } from 'src/types'; // Make sure to import this type

interface BulkCreateState {
  isLoading: boolean;
  error: Error | null;
  createdPolicies: PolicyMetadata[];
}

export const useBulkCreatePolicyMetadata = () => {
  const [state, setState] = useState<BulkCreateState>({
    isLoading: false,
    error: null,
    createdPolicies: [],
  });

  const createPoliciesInBulk = async (requests: CreatePolicyMetadataRequest[]): Promise<PolicyMetadata[]> => {
    setState((prev) => ({ ...prev, isLoading: true, error: null, createdPolicies: [] }));

    const createdPolicies: PolicyMetadata[] = [];
    const postDataClient = postData();

    for (const request of requests) {
      try {
        const params: PostApiParams<CreatePolicyMetadataRequest> = {
          path: CREATE_POLICY_METADATA_API(),
          method: 'POST',
          body: request,
        };

        const data: any = await postDataClient(params);

        const createdPolicy: PolicyMetadata = {
          ...request,
          policyId: data.policyId,
          versionNumber: data.versionNumber,
          createdBy: Date.now().toString(),
          createdAt: Date.now().toString(),
        };

        createdPolicies.push(createdPolicy);
      } catch (err) {
        setState((prev) => ({
          ...prev,
          isLoading: false,
          error: err instanceof Error ? err : new Error('An unknown error occurred'),
        }));
        return createdPolicies; // Return policies created so far created.
      }
    }

    setState((prev) => ({ ...prev, isLoading: false, createdPolicies }));
    return createdPolicies;
  };

  return {
    createPoliciesInBulk,
    bulkCreateState: state,
  };
};
