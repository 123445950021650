import React from 'react';
import { Button, ButtonIconPosition, ButtonSize, ButtonVariant } from '@amzn/stencil-react-components/button';
import { IconPencil } from '@amzn/stencil-react-components/icons';
import { Col, Row } from '@amzn/stencil-react-components/layout';
import { H2, H3, H4, Text } from '@amzn/stencil-react-components/text';
import { deserializeFromMarkdown, HtmlRenderer } from '@amzn/stencil-react-components/rte';
import { Card } from '@amzn/stencil-react-components/card';
import { useNavigate } from 'react-router-dom';
import { REVIEW_SUBMISSION_WORKFLOW_PATH } from 'src/constants';

export interface ComparisonData {
  comparisonData: string;
  executionId: string;
  version: number;
  editDisabled: boolean;
}

const PolicyAuditDetailsPage = ({ comparisonData, executionId, version, editDisabled }: ComparisonData) => {
  const navigate = useNavigate();

  const handleLineParsing = (line: string) => {
    if (line) {
      if (line.includes('<header>')) {
        return <H4>{`${line.replace('<header>', '')}`}</H4>;
      }
      return (
        <Row alignItems={'center'} gridGap={'S100'}>
          <Text>{line}</Text>
        </Row>
      );
    }
    return <></>;
  };

  return (
    <Col gridGap={'S400'} width={'100%'}>
      <Row justifyContent="space-between" alignItems={'center'} gridGap={'S300'}>
        <Col width={'60%'}>
          <H2>Policy Audit</H2>
        </Col>
        <Button
          type={'button'}
          size={ButtonSize.Small}
          icon={<IconPencil aria-hidden={true} />}
          iconPosition={ButtonIconPosition.Trailing}
          variant={ButtonVariant.Primary}
          onClick={() => {
            navigate(`${REVIEW_SUBMISSION_WORKFLOW_PATH}/${executionId}/version/${version}`);
          }}
          disabled={editDisabled}
        >
          Edit Policy
        </Button>
      </Row>
      <Card>
        <Col gridGap={'S200'} width="100%">
          <HtmlRenderer html={deserializeFromMarkdown(comparisonData) as string} />
        </Col>
      </Card>
    </Col>
  );
};

export default PolicyAuditDetailsPage;
