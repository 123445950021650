import { HttpError, postData } from 'src/clients';
import { useMutation } from '@tanstack/react-query';
import { PostApiParams } from 'src/types';
import { useState } from 'react';
import { CreateExecutionRequest, CreateExecutionResponse } from 'src/models';
import { CREATE_EXECUTION_API } from 'src/paths';

export const useCreateExecution = () => {
  const {
    isLoading: loading,
    error,
    data: responseData,
    mutate,
    isSuccess,
  } = useMutation<unknown, HttpError, PostApiParams<unknown>>(postData());
  const [retryParams, setRetryParams] = useState<CreateExecutionRequest | undefined>(undefined);
  const path = CREATE_EXECUTION_API();
  const createExecution = (createExecutionRequest: CreateExecutionRequest) => {
    setRetryParams(undefined);
    mutate(
      {
        path,
        method: 'POST',
        body: createExecutionRequest,
      },
      {
        onError: (err) => {
          setRetryParams({ ...createExecutionRequest });
        },
      },
    );
  };

  const retry = () => {
    if (!retryParams || loading) return;
    createExecution(retryParams);
  };

  return {
    createExecutionState: {
      data: responseData as CreateExecutionResponse,
      loading,
      error: retryParams ? error : null,
      retry,
      isSuccess,
    },
    createExecution,
  };
};
