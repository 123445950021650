import { HttpError, postData } from 'src/clients';
import { useMutation } from '@tanstack/react-query';
import { PostApiParams } from 'src/types';
import { useState } from 'react';
import { GetPoliciesMetadataRequest, GetPoliciesMetadataResponse } from 'src/models';
import { GET_POLICIES_METADATA_API } from 'src/paths';

export const useGetPoliciesMetadata = () => {
  const {
    isLoading: loading,
    error,
    data: responseData,
    mutate,
  } = useMutation<unknown, HttpError, PostApiParams<unknown>>(postData());
  const [retryParams, setRetryParams] = useState<GetPoliciesMetadataRequest | undefined>(undefined);
  const path = GET_POLICIES_METADATA_API();
  const getPoliciesMetadata = (getPoliciesMetadataRequest: GetPoliciesMetadataRequest) => {
    setRetryParams(undefined);
    mutate(
      {
        path,
        method: 'POST',
        body: getPoliciesMetadataRequest,
      },
      {
        onError: (err) => {
          setRetryParams({ ...getPoliciesMetadataRequest });
        },
      },
    );
  };

  const retry = () => {
    if (!retryParams || loading) return;
    getPoliciesMetadata(retryParams);
  };

  return {
    getPoliciesMetadataState: {
      data: responseData as GetPoliciesMetadataResponse,
      loading,
      error: retryParams ? error : null,
      retry,
    },
    getPoliciesMetadata,
  };
};
