import React from 'react';
import { Container } from '@amzn/stencil-react-components/layout';
import {
  convertApprovalLevelOutputToLevel,
  EditedPolicyReviewStep,
  PolicyPublishAction,
  PolicyReviewStep,
  PolicyReviewWorkflowContextProvider,
  useAuthentication,
  WorkflowStepsContainer,
} from 'src/components';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { DASHBOARD } from 'src/constants';
import { useGetReview } from 'src/hooks/useGetReview';
import { ActorRole, ApprovalLevelOutput, ReviewStatus, Level, UserApprovals } from 'src/models';
import { ProgressTrackerStepStatus } from '@amzn/stencil-react-components/progress-tracker';
import { usePerformApproverAction, usePolicyDetails } from 'src/hooks';

const PolicyReviewWorkflow = () => {
  const navigate = useNavigate();
  const { executionId, version } = useParams<{ executionId: string; version: string }>();
  const { authenticatedUser } = useAuthentication();
  const { reviewerActionState, reviewerAction } = usePerformApproverAction();

  const {
    responseState: { policyContentData: policyContentData },
  } = usePolicyDetails(executionId!, Number(version));

  const { data: reviewDetails, refetch } = useGetReview({ executionId: executionId!, version: Number(version) });

  const getLevels = (): Level[] => {
    if (!reviewDetails || !reviewDetails.approvalPlan) {
      return [];
    }
    return convertApprovalLevelOutputToLevel(reviewDetails?.approvalPlan);
  };

  return (
    <PolicyReviewWorkflowContextProvider
      policyReviewWorkflowInitial={{
        executionVersion: Number(version),
        executionId: executionId!,
        levels: getLevels(),
        policyContentDataItems: policyContentData,
        actorRole:
          reviewDetails?.requesterAlias === authenticatedUser?.userName ? ActorRole.REQUESTER : ActorRole.APPROVER,
        reviewerAction,
        reviewerActionState,
        getReview: refetch,
      }}
    >
      <Container>
        <WorkflowStepsContainer
          steps={[
            {
              labelText: 'Edited Policy View',
              renderStepComponent: () => <EditedPolicyReviewStep />,
              status: ProgressTrackerStepStatus.Complete,
            },
            {
              labelText: 'Policy Review',
              renderStepComponent: () => <PolicyReviewStep />,
              checkIfNextClickDisabled: () => {
                return (
                  !reviewDetails?.reviewStatus?.toUpperCase().includes('APPROVED') ||
                  reviewDetails?.requesterAlias !== authenticatedUser?.userName
                );
              },
            },
          ]}
          onCancel={() => navigate(DASHBOARD)} // TODO: This actually routes to dashboard.
        >
          <PolicyPublishAction />
        </WorkflowStepsContainer>
      </Container>
    </PolicyReviewWorkflowContextProvider>
  );
};

export default PolicyReviewWorkflow;
