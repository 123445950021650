import { Sha256 } from '@aws-crypto/sha256-js';
import { STS, Credentials } from '@aws-sdk/client-sts';
import { SignatureV4 } from '@aws-sdk/signature-v4';
import { ChecksumConstructor } from '@aws-sdk/types';
import axios from 'axios';
import { getIAMCredentialsFromStorage } from 'src/utils';

/**
 * Refresh credentials 5 mins (300000 milliseconds) before expiration.
 */
const CREDENTIALS_REFRESH_BUFFER = 30000;
const ROLE_SESSION_NAME = 'PAPI';

/**
 * Http client to make signed requests to a host. Credentials will be automatically refreshed.
 * @param host PAPI host to which requests will be made
 * @param papiRoleArn Role to be assumed to make requests. Note that this role should have permissions to call PAPI in `region`.
 */
export class AWSHttpClient {
  /**
   * Creates a new SignatureV4 signer to sign requests sent to PAPI.
   */
  static getSigner(credentials: Credentials | undefined, region: string, sha256: ChecksumConstructor) {
    return new SignatureV4({
      credentials: {
        accessKeyId: credentials?.AccessKeyId || '',
        secretAccessKey: credentials?.SecretAccessKey || '',
        sessionToken: credentials?.SessionToken || '',
      },
      region: region,
      service: 'execute-api',
      sha256,
    });
  }

  /**
   * Async method to create a new AWSHttpClient object.
   */
  static async createInstance(host: string, region: string, assumeRoleArn: string) {
    const stsClient = await AWSHttpClient.getSTSClient(region);
    const assumedCredentials = await AWSHttpClient.getAssumeRoleCredentials(stsClient, assumeRoleArn);
    const signer = AWSHttpClient.getSigner(assumedCredentials, region, Sha256);
    return new AWSHttpClient(host, region, assumedCredentials, assumeRoleArn, signer, stsClient);
  }

  private static async getSTSClient(region: string): Promise<STS> {
    const awsCredentials = getIAMCredentialsFromStorage();
    return new STS({
      region,
      credentials: {
        accessKeyId: awsCredentials.accessKeyId,
        secretAccessKey: awsCredentials.secretAccessKey,
        sessionToken: awsCredentials.sessionToken,
      },
    });
  }

  private static async getAssumeRoleCredentials(
    stsClient: STS,
    assumeRoleArn: string,
  ): Promise<Credentials | undefined> {
    const assumedRoleOutput = await stsClient.assumeRole({
      RoleArn: assumeRoleArn,
      RoleSessionName: ROLE_SESSION_NAME,
    });
    return assumedRoleOutput.Credentials;
  }

  private readonly host: string;
  private readonly region: string;
  private readonly assumeRoleArn: string;
  private readonly stsClient: STS;
  private credentials: Credentials | undefined;
  private signer: SignatureV4;

  constructor(
    host: string,
    region: string,
    credentials: Credentials | undefined,
    papiRoleArn: string,
    signer: SignatureV4,
    stsClient: STS,
  ) {
    this.host = host;
    this.region = region;
    this.assumeRoleArn = papiRoleArn;
    this.stsClient = stsClient;
    this.credentials = credentials;
    this.signer = signer;
  }

  private credentialsExpired() {
    if (!this.credentials || !this.credentials.Expiration) {
      return true;
    }
    const expirationTimeStamp = this.credentials.Expiration!.getTime();
    const currentTimeStamp = new Date().getTime();
    return expirationTimeStamp < currentTimeStamp + CREDENTIALS_REFRESH_BUFFER;
  }

  private async refreshCredentials() {
    if (!this.credentialsExpired()) {
      return;
    }
    this.credentials = await AWSHttpClient.getAssumeRoleCredentials(this.stsClient, this.assumeRoleArn);
    this.signer = AWSHttpClient.getSigner(this.credentials, this.region, Sha256);
  }

  get(path: string, query: any) {
    return this._sendHttpRequest('GET', path, undefined, query);
  }

  post(path: string, body: any) {
    return this._sendHttpRequest('POST', path, body, undefined);
  }

  /**
   * Async method to make `GET`/`POST` signed requests to `host`.
   * @param method `GET` | `POST`
   * @param path path for specific resource
   * @param body for `POST` requests
   * @param query for `GET` requests
   * @returns Promise to AxiosResponse object
   */
  async _sendHttpRequest(method: string, path: string, body: any, query: any) {
    await this.refreshCredentials();

    const request: any = {
      protocol: 'https:',
      hostname: this.host,
      path,
      method,
      headers: { Host: this.host },
    };

    if (body) {
      request.body = JSON.stringify(body);
      request.headers['Content-Type'] = 'application/json';
      request.headers['Content-Length'] = `${Buffer.byteLength(request.body, 'utf8')}`;
    }

    if (query) {
      request.query = query;
    }

    const signedRequest: any = await this.signer.sign(request);
    return axios({
      url: `https://${signedRequest.hostname}${signedRequest.path}`,
      params: method === 'GET' ? signedRequest.query : undefined,
      data: method === 'POST' ? signedRequest.body : undefined,
      method: signedRequest.method,
      headers: signedRequest.headers,
      responseType: 'json',
    });
  }
}
