import React, { createContext, useContext } from 'react';
import { CreateReviewRequest, CreateReviewResponse, Level, PolicyExecutionResponse } from 'src/models';
import { HttpError } from 'src/clients';
import { Nullable } from 'src/types';
import { PolicyContentData } from 'src/hooks';

export interface ReviewSubmissionWorkflowContextType {
  levels: Level[];
  setLevels: React.Dispatch<React.SetStateAction<Level[]>>;
  createReview: (createReviewRequest: CreateReviewRequest) => void;
  createReviewState: {
    data: CreateReviewResponse;
    loading: boolean;
    error: HttpError | null;
    retry: () => void;
  };
  policyExecutionState: {
    executionId: string;
    policyExecutionResponse: Nullable<PolicyExecutionResponse>;
    policyContentData: PolicyContentData[];
    policyExecutionError: HttpError | unknown;
    policyExecutionLoading: boolean;
  };
  needByDate: string;
  setNeedByDate: (needByDate: string) => void;
}

export const ReviewSubmissionWorkflowContext = createContext<ReviewSubmissionWorkflowContextType | undefined>(
  undefined,
);

export const ReviewSubmissionWorkflowContextProvider: React.FC<{
  reviewSubmissionWorkflowInitial: ReviewSubmissionWorkflowContextType;
  children: React.ReactNode;
}> = ({ reviewSubmissionWorkflowInitial, children }) => {
  return (
    <ReviewSubmissionWorkflowContext.Provider
      value={{
        ...reviewSubmissionWorkflowInitial,
      }}
    >
      {children}
    </ReviewSubmissionWorkflowContext.Provider>
  );
};

export const useReviewSubmissionWorkflowContext = () => {
  const context = useContext(ReviewSubmissionWorkflowContext);
  if (!context) {
    throw new Error('useReviewSubmissionWorkflowContext must be used within a ReviewSubmissionWorkflowContextProvider');
  }
  return context;
};
