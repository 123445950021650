/**
 * Generates EPOCH timestamp from date string.
 * @param date
 */
export const getEpochFromDateString = (date: string): number => new Date(date).getTime() / 1000;

/**
 * Returns today/current date as string.
 */
export const getTodayDate = (): string => {
  const date = new Date();
  return date.toISOString();
};

export const NUMBER_OF_MILLISECONDS_IN_A_SECOND = 1000;
