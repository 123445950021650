export enum DomainType {
  TIME = 'Timeoff', //TODO
  PAY = 'PAY',
}

export interface TrackingSources {
  referenceId: string;
  sourceType: SourceType;
}

export interface PolicyMetadataAttributes {
  country?: string;
  states?: string[];
  jobClasses?: string[];
}

export enum SourceType {
  FILE = 'FILE',
  GOVT = 'GOVT',
  INSIDE = 'INSIDE',
  LINK = 'LINK',
  MY_TIME = 'MY_TIME',
  POLICY_2_0 = 'POLICY_2_0',
}

export enum ComparisonGroupType {
  A = 'A',
  B = 'B',
}

export interface Policy {
  policyId: string;
  version: number;
  domain: string;
  editedS3Path?: string;
  name: string;
  s3Path?: string;
  comparisonGroup: ComparisonGroupType;
  sourceType: SourceType;
}
export interface UserInput {
  aspectIds?: string[];
  definitionIds?: string[];
  additionalResources?: AdditionalResources[];
  additionalInstructions?: string;
}

export enum AdditionalResourceType {
  FILE = 'FILE',
  LINK = 'LINK',
}

export interface AdditionalResources {
  type: AdditionalResourceType;
  group: ComparisonGroupType;
  path?: string;
  link?: string;
}
