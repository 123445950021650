import React from 'react';
import { View, Text, StyleSheet, Page, Document } from '@react-pdf/renderer';

const styles = StyleSheet.create({
  heading: { fontSize: 20, marginTop: 10, marginBottom: 10 },
  title: { fontSize: 15, marginTop: 8, marginBottom: 8 },
  data: { fontSize: 12, marginTop: 3, marginBottom: 3 },
  section: { color: 'black', textAlign: 'left', margin: 30 },
  case: { marginTop: 10, marginBottom: 7 },
  content: { padding: 30 },
});

interface PolicyPdfViewProps {
  policyName: string;
  policy: string;
}

const PolicyPdfView: React.FC<PolicyPdfViewProps> = ({ policyName, policy }) => {
  return (
    <Document>
      <Page size={'A4'} style={styles.content}>
        <View style={styles.section}>
          <Text style={styles.heading}>{policyName}</Text>
          <Text style={styles.data}>{policy}</Text>
        </View>
      </Page>
    </Document>
  );
};

export default PolicyPdfView;
