import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getData } from 'src/clients';
import { GetReviewRequest, GetReviewResponse } from 'src/models';
import { GET_REVIEW_API } from 'src/paths';

export const useGetReview = ({ executionId, version }: GetReviewRequest): UseQueryResult<GetReviewResponse> => {
  const path = GET_REVIEW_API(executionId, version);
  return useQuery<unknown, unknown, GetReviewResponse>(
    ['get-review', executionId, version],
    () =>
      getData<GetReviewResponse>({
        path,
      }),
    {
      enabled: !!executionId && !!version,
    },
  );
};
