import React from 'react';
import { Col, Row } from '@amzn/stencil-react-components/layout';
import { RichTextEditor } from '@amzn/stencil-react-components/rte';

// TODO: Mark executionResult as required once integration is complete
interface EditorWithComparisonViewerProps {
  isReadOnlyMode: boolean;
  policyContent: string;
  policyContentUpdateCallback?: (updatedPolicy: string) => void;
}

const EditorViewer: React.FC<EditorWithComparisonViewerProps> = ({
  isReadOnlyMode,
  policyContent,
  policyContentUpdateCallback,
}) => {
  return (
    <>
      <Col padding={'S100'} justifyContent={'left'}>
        <Row>
          <RichTextEditor
            maxHeight={'70vh'}
            readOnly={isReadOnlyMode}
            defaultContent={policyContent}
            onChange={(editor) => {
              if (policyContentUpdateCallback) policyContentUpdateCallback(editor.getText());
            }}
          />
        </Row>
      </Col>
    </>
  );
};

export default EditorViewer;
