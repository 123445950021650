import { ChipBackgroundColor } from '@amzn/stencil-react-components/chip';
import { ApprovalLevelOutput, Level, ReviewStatus, UserApprovals } from 'src/models';

export const getBGColor = (status: string) => {
  if (status.toLowerCase().includes('pending')) return ChipBackgroundColor.Yellow;
  else if (status.toLowerCase().includes('approved')) return ChipBackgroundColor.Green;
  else if (status.toLowerCase().includes('draft')) return ChipBackgroundColor.Default;
  else return ChipBackgroundColor.Red;
};

export const convertString = (inputString: string) => {
  // Convert to lowercase, replace underscores with spaces
  let formattedString = inputString.replace(/_/g, ' ').toLowerCase();

  // Capitalize the first letter of each word
  formattedString = formattedString
    .split(' ')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');

  return formattedString;
};

const LevelTitle = ['Business', 'Legal']; //TODO: Remove the hardcoded titles after demo

export const convertApprovalLevelOutputToLevel = (levelInfoArray: ApprovalLevelOutput[]): Level[] => {
  return levelInfoArray.map((levelInfo, index) => {
    const reviewers = levelInfo.userApprovals.map((userApproval: UserApprovals) => userApproval.approver.alias);

    const reviewersWithStatus: Record<string, ReviewStatus> = levelInfo.userApprovals.reduce(
      (acc: Record<string, ReviewStatus>, userApproval: UserApprovals) => {
        acc[userApproval.approver.alias] = userApproval.approvalStatus;
        return acc;
      },
      {} as Record<string, ReviewStatus>,
    );

    return {
      levelName: index >= LevelTitle.length ? `Level ${index + 1}` : LevelTitle[index], // Use title or default to Level <number>
      reviewers,
      reviewersWithStatus,
    };
  });
};
