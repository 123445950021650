import React from 'react';
import {
  ProgressTracker,
  ProgressTrackerStep,
  ProgressTrackerStepProps,
  ProgressTrackerStepStatus,
} from '@amzn/stencil-react-components/progress-tracker';
import { Col, Flex, Hr } from '@amzn/stencil-react-components/layout';
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import { Step, useStepProgress } from 'src/components';

// TODO: Remove step from optional to required as follow up.
interface WorkflowStepsContainerProps {
  children?: React.ReactNode;
  steps?: Step[];
  onCancel: () => void;
}

// TODO: Remove the default the steps array as follow up.
const WorkflowStepsContainer: React.FC<WorkflowStepsContainerProps> = ({
  children,
  steps = [{ labelText: 'Ask for feedback' }, { labelText: 'Review and submit' }, { labelText: 'Discuss' }],
  onCancel,
}) => {
  const {
    currentStep,
    setCurrentStep,
    onNext,
    onBack,
    completedSteps,
    isNextDisabled,
    currentStepShowActions: showActions,
  } = useStepProgress({ steps });

  const renderStep = (props: ProgressTrackerStepProps, index: number): JSX.Element => {
    let status;
    if (completedSteps[index]) {
      status = ProgressTrackerStepStatus.Complete;
    } else if (index > 0 && !completedSteps[index - 1]) {
      status = ProgressTrackerStepStatus.Disabled;
    } else {
      status = props.status;
    }
    return <ProgressTrackerStep key={index} {...props} status={status} />;
  };

  return (
    <Col gridGap="S200">
      <ProgressTracker selectedStep={currentStep} onStepSelected={(selectedStep) => setCurrentStep(selectedStep)}>
        {steps.map(renderStep)}
      </ProgressTracker>
      <Col padding="S400" gridGap={'S300'}>
        {currentStep < steps.length ? <>{steps[currentStep]?.renderStepComponent?.()}</> : children}
        {showActions ? (
          <Flex flexDirection={'row'} justifyContent={'space-between'} padding={'S300'}>
            {currentStep < steps.length && (
              <Button onClick={onCancel} variant={ButtonVariant.Tertiary}>
                Cancel
              </Button>
            )}
            <Flex flexDirection={'row-reverse'} gridGap={'S300'}>
              {currentStep < steps.length && (
                <Button onClick={onNext} disabled={isNextDisabled} variant={ButtonVariant.Primary}>
                  {currentStep === steps.length - 1 ? 'Complete' : 'Next'}
                </Button>
              )}
              {currentStep < steps.length && currentStep !== 0 && <Button onClick={onBack}> Back</Button>}
            </Flex>
          </Flex>
        ) : null}
      </Col>
    </Col>
  );
};

export default WorkflowStepsContainer;
