import React from 'react';
import { PolicyEditorComponent } from 'src/components/shared';
import { usePolicyReviewWorkflowContext } from 'src/components';
import { Col } from '@amzn/stencil-react-components/layout';

const EditedPolicyReviewStep = () => {
  const { policyContentDataItems } = usePolicyReviewWorkflowContext();

  const renderEditedPolicies = () => {
    return policyContentDataItems
      .filter((policyContentData) => policyContentData.editedContent)
      .map((policyContentData, index) => (
        <PolicyEditorComponent
          key={policyContentData.policyId}
          isReadOnlyMode
          executionResult={{
            updatedPolicy: policyContentData.editedContent || '',
            currentPolicy: policyContentData.originalContent || '',
          }}
        />
      ));
  };

  return <Col width={'100%'}>{renderEditedPolicies()}</Col>;
};

export default EditedPolicyReviewStep;
