import { UserInput, Policy } from './common';

export enum ExecutionType {
  ASYNCHRONOUS_COMPARISON = 'ASYNCHRONOUS_COMPARISON',
}

export interface CreateExecutionRequest {
  requesterAlias: string;
  namespaceId: string;
  namespaceVersion: number;
  policies: Policy[];
  executionType: ExecutionType;
  userInput: UserInput;
}

export interface CreateExecutionResponse {
  executionId: string;
  version: string;
}
