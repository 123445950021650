import React from 'react';
import { Col } from '@amzn/stencil-react-components/layout';
import ReactDiffViewer from 'react-diff-viewer-continued';

const DiffViewerComponent: React.FC<{ oldData: string; newData: string }> = ({ oldData, newData }) => {
  return (
    <Col padding={'S100'}>
      <ReactDiffViewer oldValue={oldData} newValue={newData} splitView={true} />
    </Col>
  );
};

export default DiffViewerComponent;
