import React, { useState } from 'react';
import { Container, Col, Row } from '@amzn/stencil-react-components/layout';
import PolicyComparisonTable from 'src/components/dashboard/PolicyExecutionTable';
import PolicyReviewTable from 'src/components/dashboard/PolicyReviewTable';
import DashboardCard from 'src/components/dashboard/DashboardCards';
import { CardStatus } from '@amzn/stencil-react-components/card';
import { ActorRole } from 'src/models/api-models/getReviews';
import { useGetDashboardData } from 'src/components/dashboard/hooks/useGetDashboardData';
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import { useNavigate } from 'react-router-dom';
import { CREATE_EXECUTION } from 'src/constants';

enum CardType {
  EXECUTIONS = 'Executions',
  REVIEWS_REQUSTED = 'ReviewsRequested',
  NEED_TO_REVIEW = 'NeedToReview',
}

interface TableCounts {
  reviewsRequestedCount: number;
  executionsCount: number;
  needToReviewCount: number;
}

const Dashboard = () => {
  const [activeCard, setActiveCard] = useState<CardType>(CardType.EXECUTIONS);
  const navigate = useNavigate();
  const { reviewsRequested, assignedReviews, executions, counts } = useGetDashboardData();

  const handleCardClick = (cardName: CardType) => {
    setActiveCard(cardName);
  };

  const cardData: { name: CardType; title: string; count: number; status: CardStatus }[] = [
    { name: CardType.EXECUTIONS, title: 'My Assessments', count: counts.executionCount, status: CardStatus.Default },
    {
      name: CardType.REVIEWS_REQUSTED,
      title: 'Reviews Requested',
      count: counts.reviewsRequestedCount,
      status: CardStatus.Default,
    },
    {
      name: CardType.NEED_TO_REVIEW,
      title: 'Need to Review',
      count: counts.needToReviewCount,
      status: CardStatus.Warning,
    },
  ];

  return (
    <Container>
      <Col gridGap={'S500'} padding={'S300'}>
        <Container>
          <Row gridGap={20}>
            {cardData.map((card) => (
              <DashboardCard
                key={card.name}
                title={card.title}
                count={card.count}
                isActive={activeCard === card.name}
                onClick={() => handleCardClick(card.name)}
                status={card.status}
              />
            ))}
          </Row>
        </Container>
        <Col>
          <Row justifyContent="flex-end">
            <Button variant={ButtonVariant.Primary} onClick={() => navigate(CREATE_EXECUTION)}>
              Start Assessment
            </Button>
          </Row>
        </Col>
        {executions.data && activeCard === CardType.EXECUTIONS && (
          <PolicyComparisonTable data={executions.data.executions} />
        )}
        {reviewsRequested.data?.reviews && activeCard === CardType.REVIEWS_REQUSTED && (
          <PolicyReviewTable actorRole={ActorRole.REQUESTER} data={reviewsRequested.data.reviews} />
        )}
        {assignedReviews.data?.reviews && activeCard === CardType.NEED_TO_REVIEW && (
          <PolicyReviewTable actorRole={ActorRole.APPROVER} data={assignedReviews.data.reviews} />
        )}
      </Col>
    </Container>
  );
};

export default Dashboard;
