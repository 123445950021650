import { useQueries } from '@tanstack/react-query';
import { getData } from 'src/clients';
import { GET_PRE_SIGNED_URL, GET_PRESIGNED_URL_REQUEST_PARAMS, SERVICE } from 'src/paths';

export enum FileOperationType {
  DOWNLOAD = 'DOWNLOAD',
  UPLOAD = 'UPLOAD',
}

export interface GetPresignedUrlRequest {
  fileOperationType: FileOperationType;
  s3FilePath: string;
  service: SERVICE;
}

export const useGetPreSignedUrl = (request: GetPresignedUrlRequest[]) => {
  return useQueries({
    queries: request.map(({ fileOperationType, service, s3FilePath }) => ({
      queryKey: ['preSignedUrl', s3FilePath, fileOperationType],
      queryFn: () =>
        getData({
          path: GET_PRE_SIGNED_URL({
            fileOperationType: fileOperationType,
            service: service,
            s3FilePath: s3FilePath,
          } as GET_PRESIGNED_URL_REQUEST_PARAMS),
        }),
      enabled: !!s3FilePath && !!fileOperationType,
      staleTime: 1000 * 60, // Cache for 1 minute
      retry: true,
      retryDelay: (attempt: number) => Math.min(1000 * 2 ** attempt, 10000), // Exponential backoff
    })),
  });
};
