import React from 'react';
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import { FileUpload } from '@amzn/stencil-react-components/file-upload';
import { FormWrapper, Input, InputWrapper, Select, TextArea } from '@amzn/stencil-react-components/form';
import { Col, Container, Row, Spacer } from '@amzn/stencil-react-components/layout';
import { H2 } from '@amzn/stencil-react-components/text';
import { DomainType, SourceType } from 'src/models';
import { COUNTRY_OPTIONS, usePolicyMetadataInputHook } from './hooks';

interface PolicyMetadataFormProps {
  onSubmit: () => void;
  namespaceId: string;
}

export const PolicyMetadataInputForm: React.FC<PolicyMetadataFormProps> = ({ onSubmit, namespaceId }) => {
  const {
    name,
    description,
    sourceType,
    domain,
    policyMetadataAttributesOptions,
    policyMetadataAttributes,
    formFieldErrors,
    handleCountryChange,
    handleDomainChange,
    handleInputChange,
    selectedAttributes,
    updateAttributes,
    handleSourceTypeChange,
    handleSubmit,
    handleFileUpload,
    handleLinkInput,
  } = usePolicyMetadataInputHook(namespaceId);

  const getSourceTypeInputField = (type: SourceType | undefined) => {
    switch (type) {
      case SourceType.INSIDE:
        return (
          <InputWrapper
            required={true}
            labelText="File Upload"
            //TODO: Once file upload is handled update the validation
            id="policy-metadata-file-upload"
          >
            {(inputProps) => (
              <FileUpload
                maxFiles={1}
                accept=".doc,.docx,.pdf,.txt"
                onFileAttached={(files, rejectedFiles, failedFiles) => {
                  //TODO: Handle rejected and failed files
                  handleFileUpload(files);
                }}
                {...inputProps}
              />
            )}
          </InputWrapper>
        );
      case SourceType.GOVT:
        return (
          <InputWrapper
            required={true}
            labelText="Link"
            id="policy-metadata-link"
            error={formFieldErrors.trackingSources.isError}
            footer={formFieldErrors.trackingSources.message}
          >
            {(inputProps) => (
              <Input
                onChange={(event) => handleLinkInput(event.target.value)}
                placeholder="Enter Website link"
                type="url"
                {...inputProps}
              />
            )}
          </InputWrapper>
        );
      default:
        return <></>;
    }
  };

  return (
    <Container>
      <Col backgroundColor={'neutral0'} width={'50rem'} gridGap={'S400'} padding={'S500'}>
        <H2>Add Policy Metadata</H2>
        <FormWrapper>
          <InputWrapper
            id="policy-metadata-name"
            labelText="Policy Name"
            required={true}
            error={formFieldErrors.name.isError}
            footer={formFieldErrors.name.message}
          >
            {(inputProps) => (
              <Input
                placeholder="Enter name"
                value={name}
                onChange={(e) => handleInputChange('name', e.target.value)}
                {...inputProps}
              />
            )}
          </InputWrapper>
          <InputWrapper id="policy-metadata-description" labelText="Policy Description">
            {(inputProps) => (
              <TextArea
                resize={'vertical'}
                placeholder="Enter description"
                value={description}
                onChange={(e) => handleInputChange('description', e.target.value)}
                {...inputProps}
              />
            )}
          </InputWrapper>
          <InputWrapper
            id="policy-metadata-domain"
            labelText="Domain"
            required={true}
            error={formFieldErrors.domain.isError}
            footer={formFieldErrors.domain.message}
          >
            {(inputProps) => (
              <Select
                options={Object.values(DomainType)}
                placeholder="Select domain"
                value={domain}
                onChange={(value) => handleDomainChange(value)}
                {...inputProps}
              />
            )}
          </InputWrapper>
          <InputWrapper
            id="policy-metadata-country"
            labelText="Country"
            required={true}
            error={formFieldErrors.country.isError}
            footer={formFieldErrors.country.message}
          >
            {(inputProps) => (
              <Select
                options={COUNTRY_OPTIONS}
                placeholder="Select country"
                value={policyMetadataAttributes.country}
                onChange={(value) => handleCountryChange(value)}
                {...inputProps}
              />
            )}
          </InputWrapper>
          <InputWrapper
            id="policy-metadata-attributes"
            labelText="Attributes"
            required={true}
            error={formFieldErrors.policyMetadataAttributes.isError}
            footer={formFieldErrors.policyMetadataAttributes.message}
          >
            {(inputProps) => (
              <Select
                labelId="policy-metadata-attributes-label"
                options={policyMetadataAttributesOptions}
                isMulti={true}
                value={selectedAttributes}
                onChange={updateAttributes}
                {...inputProps}
              />
            )}
          </InputWrapper>
          <InputWrapper
            id="policy-metadata-source-type"
            labelText="Source Type"
            required={true}
            error={formFieldErrors.sourceType.isError}
            footer={formFieldErrors.sourceType.message}
          >
            {(inputProps) => (
              <Select
                options={[
                  SourceType.GOVT,
                  SourceType.INSIDE,
                  'Standard Operating Procedure [SOP]',
                  'Knowledge Base [KB]',
                ]} // TODO: Update this list to support other source type.
                value={sourceType}
                onChange={(value) => handleSourceTypeChange(value)}
                placeholder="Select source type"
                {...inputProps}
              />
            )}
          </InputWrapper>
          {getSourceTypeInputField(sourceType)}
          <Spacer height={'S400'} />
          <Row justifyContent={'right'} width={'100%'}>
            <Button onClick={() => handleSubmit(onSubmit)} variant={ButtonVariant.Primary}>
              Submit
            </Button>
          </Row>
        </FormWrapper>
      </Col>
    </Container>
  );
};
