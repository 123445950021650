import React from 'react';
import { DetailedCheckbox } from '@amzn/stencil-react-components/form';
import { useCreateExecutionWorkflowContext } from '../context';
import { ParameterType } from '../hooks';

interface ParameterTileProps {
  parameter: ParameterType;
  viewMode: boolean;
}

export const ParameterTile: React.FC<ParameterTileProps> = ({ viewMode, parameter }) => {
  const { selectedParameters, handleParameterChange } = useCreateExecutionWorkflowContext();

  return (
    <DetailedCheckbox
      disabled={viewMode}
      key={parameter.id}
      value={parameter.id}
      titleText={parameter.comparisonAspect}
      details={parameter.definition}
      checked={selectedParameters?.includes(parameter)}
      onChange={(e) => handleParameterChange(parameter, e.target.checked)}
    />
  );
};
