import { useMutation } from '@tanstack/react-query';
import { useState } from 'react';
import { FileOperationType } from 'src/hooks';
import { getData } from 'src/clients';
import { GET_PRE_SIGNED_URL, GET_PRESIGNED_URL_REQUEST_PARAMS, SERVICE } from 'src/paths';

export interface UploadFileContentMetadata {
  fileContent: string;
  service: SERVICE;
  s3Path?: string;
  fileName?: string;
  namespaceId?: string;
}

const getPreSignedURL = (fileOperationType: FileOperationType, fileContent: UploadFileContentMetadata) => {
  return getData({
    path: GET_PRE_SIGNED_URL({
      fileOperationType: fileOperationType,
      service: fileContent.service,
      s3FilePath: fileContent.s3Path,
      fileName: fileContent.fileName,
      namespaceId: fileContent.namespaceId,
    } as GET_PRESIGNED_URL_REQUEST_PARAMS),
  });
};

const uploadFileToS3 = async (fileContentWithS3Path: UploadFileContentMetadata) => {
  const preSignedURL: any = await getPreSignedURL(FileOperationType.UPLOAD, fileContentWithS3Path);
  const response = await fetch(preSignedURL['preSignedUrl'], {
    method: 'PUT',
    headers: {
      'Content-Type': 'text/plain',
    },
    body: fileContentWithS3Path.fileContent,
  });

  if (!response.ok) {
    throw new Error(`Failed to upload file to S3`);
  }
  return preSignedURL['filePath'];
};

export const useUploadFilesOnPreSignedURL = () => {
  const [retryParams, setRetryParams] = useState<UploadFileContentMetadata[] | undefined>(undefined);

  const uploadFiles = async (fileContentWithURLList: UploadFileContentMetadata[]) => {
    return [await Promise.all(fileContentWithURLList.map((item) => uploadFileToS3(item)))];
  };

  const { isLoading: loading, error, mutate, data } = useMutation(uploadFiles);

  const upload = (fileContentWithFilePaths: UploadFileContentMetadata[]) => {
    setRetryParams(undefined);
    try {
      mutate(fileContentWithFilePaths, {
        onError: () => {
          setRetryParams({ ...fileContentWithFilePaths });
        },
      });
    } catch (error) {
      setRetryParams(fileContentWithFilePaths);
    }
  };

  const retry = () => {
    if (!retryParams || loading) return;
    upload(retryParams);
  };

  return {
    uploadState: {
      data,
      loading,
      error: retryParams ? error : null,
      retry,
    },
    uploadFilesOnPreSignedURL: upload,
  };
};
