import React from 'react';
import ReactDOM from 'react-dom';
import { Spinner, SpinnerSize } from '@amzn/stencil-react-components/spinner';
import { StencilProvider } from '@amzn/stencil-react-components/context';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { App } from './components/App';
import { AppInitWrapper } from './components/AppInitWrapper';
import { AuthenticationProvider } from './components/Authentication';

ReactDOM.render(
  <React.StrictMode>
    <StencilProvider>
      <AppInitWrapper>
        <QueryClientProvider client={new QueryClient()}>
          <React.Suspense fallback={<Spinner size={SpinnerSize.Large} />}>
            <AuthenticationProvider>
              <App />
            </AuthenticationProvider>
          </React.Suspense>
        </QueryClientProvider>
      </AppInitWrapper>
    </StencilProvider>
  </React.StrictMode>,
  document.getElementById('root'),
);
