import { Stage } from 'src/hooks';

export enum REGION {
  PDX = 'us-west-2',
  IAD = 'us-east-1',
  DUB = 'eu-west-1',
}

const PAPI_NON_PROD_IAM_CONFIG = new Map<REGION, string>([
  [REGION.IAD, 'arn:aws:iam::671313004605:role/IAMAuth_PolicyEvaluationService_us-east-1'],
  [REGION.PDX, 'arn:aws:iam::671313004605:role/IAMAuth_PolicyEvaluationService_us-west-2'],
  [REGION.DUB, 'arn:aws:iam::675231445663:role/IAMAuth_PolicyEvaluationService_eu-west-1'],
]);

const PAPI_PROD_IAM_CONFIG = new Map<REGION, string>([
  [REGION.IAD, 'arn:aws:iam::220627861680:role/IAMAuth_PolicyEvaluationService_us-east-1'],
  [REGION.PDX, 'arn:aws:iam::220627861680:role/IAMAuth_PolicyEvaluationService_us-west-2'],
  [REGION.DUB, 'arn:aws:iam::100846578111:role/IAMAuth_PolicyEvaluationService_eu-west-1'],
]);

const PAPI_NON_PROD_ENDPOINT_CONFIG = new Map<REGION, string>([
  [REGION.IAD, 'us-east-1.gamma.papi.people-data.amazon.dev'],
  [REGION.PDX, 'us-west-2.gamma.papi.people-data.amazon.dev'],
  [REGION.DUB, 'eu-west-1.gamma.papi.people-data.amazon.dev'],
]);

const PAPI_PROD_ENDPOINT_CONFIG = new Map<REGION, string>([
  [REGION.IAD, 'us-east-1.prod.papi.people-data.amazon.dev'],
  [REGION.PDX, 'us-west-2.prod.papi.people-data.amazon.dev'],
  [REGION.DUB, 'eu-west-1.prod.papi.people-data.amazon.dev'],
]);
/**
 * PAPI
 *
 * GAMMA & PROD stage
 * SIM Link : https://issues.amazon.com/issues/HCM-41279
 * Automated onboarding steps completed successfully for the IAM service: Amazon Policy Advisor.
 *
 *  End Points:
 *    https://us-west-2.gamma.papi.people-data.amazon.dev,
 *    https://us-east-1.gamma.papi.people-data.amazon.dev,
 *    https://eu-west-1.gamma.papi.people-data.amazon.dev
 *
 *  https://code.amazon.com/packages/PAPITypescriptClient/blobs/mainline/--/src/PAPI.ts
 *
 * Swagger:
 *
 *   https://corp.papi.gamma.us-west-2.charisma.a2z.com:1443/explorer/index.html#/Employee%20API/employeeByLogin
 */
export const PAPI_IAM_ROLE_MAP = new Map<Stage, Map<REGION, string>>([
  [Stage.TEST, PAPI_NON_PROD_IAM_CONFIG],
  [Stage.BETA, PAPI_NON_PROD_IAM_CONFIG],
  [Stage.GAMMA, PAPI_NON_PROD_IAM_CONFIG],
  [Stage.PROD, PAPI_PROD_IAM_CONFIG],
]);

export const PAPI_ENDPOINT_MAP = new Map<Stage, Map<REGION, string>>([
  [Stage.TEST, PAPI_NON_PROD_ENDPOINT_CONFIG],
  [Stage.BETA, PAPI_NON_PROD_ENDPOINT_CONFIG],
  [Stage.GAMMA, PAPI_NON_PROD_ENDPOINT_CONFIG],
  [Stage.PROD, PAPI_PROD_ENDPOINT_CONFIG],
]);
