import React, { useEffect } from 'react';
import { Col, Container, Row, Spacer } from '@amzn/stencil-react-components/layout';
import { H1, H2, Text } from '@amzn/stencil-react-components/text';
import MyTimeVsGovtDetailsPage from 'src/components/policy-details/MyTimeVsGovtDetailsPage';
import { useDetailsPage } from 'src/components';
import { useLocation } from 'react-router-dom';
import { Spinner, SpinnerSize } from '@amzn/stencil-react-components/spinner';
import { IconDocument, IconSize } from '@amzn/stencil-react-components/icons';
import { Chip, ChipBackgroundColor, ChipSize } from '@amzn/stencil-react-components/chip';
import { Select } from '@amzn/stencil-react-components/form';
import { Card } from '@amzn/stencil-react-components/card';
import { ComparisonGroupType } from 'src/models';
import { Link } from '@amzn/stencil-react-components/link';
import { useParams } from 'react-router';
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner';
import { ExecutionStatus } from 'src/models/api-models/getExecutions';
import PolicyAuditDetailsPage from './PolicyAuditDetailsPage';
import MyTimeAuditDetailsPage from './MyTimeAuditDetailsPage';

// TODO : Move it to a separate constant file.
const policyComparisonToggleMap = {
  COMPARE_AMAZON_GOVT_POLICY: 'Compare with government policy',
  COMPARE_AMAZON_MY_TIME_POLICY: 'Compare with MyTime policy',
  COMPARE_MY_TIME_AND_GOVT_POLICY: 'Compare MyTime & Govt Policy',
};

const DetailsPage = () => {
  const { executionId, version } = useParams<{ executionId: string; version: string }>();
  const {
    setCompareOptions,
    compareOptions,
    responseState: { getExecutionResponse, policyContentData },
    isLoading,
    groupBasedPoliciesMap,
  } = useDetailsPage({ executionId: executionId!, version: Number(version) });

  useEffect(() => {
    setCompareOptions(policyComparisonToggleMap.COMPARE_AMAZON_GOVT_POLICY);
  }, []);

  const renderContentBasedOnExecutionStatus = () => {
    switch (getExecutionResponse?.executionStatus) {
      case ExecutionStatus.COMPARISON_FAILED:
      case ExecutionStatus.POLICY_COLLECTION_FAILED:
        return (
          <MessageBanner type={MessageBannerType.Error}>
            <Text fontSize={'T200'}>The execution has failed. Please create another execution request.</Text>
          </MessageBanner>
        );
      case ExecutionStatus.POLICY_COLLECTION_NOT_STARTED:
      case ExecutionStatus.POLICY_COLLECTION_IN_PROGRESS:
      case ExecutionStatus.POLICY_COLLECTION_COMPLETED:
      case ExecutionStatus.COMPARISON_NOT_STARTED:
      case ExecutionStatus.COMPARISON_IN_PROGRESS:
        return (
          <MessageBanner type={MessageBannerType.Informational}>
            <Text fontSize={'T200'}>
              The execution is still in progress with the following status: In Progress. Please refresh after sometime.
            </Text>
          </MessageBanner>
        );
      case ExecutionStatus.COMPARISON_COMPLETED:
        return renderPolicyAuditComponent(false);
      default: // For all other status other disable the edit button, but render content.
        return renderPolicyAuditComponent(true);
    }
  };

  const renderPolicyAuditComponent = (editDisabled: boolean) => {
    switch (compareOptions) {
      case policyComparisonToggleMap['COMPARE_AMAZON_GOVT_POLICY']:
        return (
          <PolicyAuditDetailsPage
            executionId={executionId!}
            version={Number(version!)}
            editDisabled={editDisabled}
            comparisonData={getExecutionResponse?.policyComparisonData?.result ?? ''}
          />
        );
      case policyComparisonToggleMap['COMPARE_AMAZON_MY_TIME_POLICY']:
        return <MyTimeAuditDetailsPage auditData={getExecutionResponse?.policyComparisonData?.result ?? ''} />;
      case policyComparisonToggleMap['COMPARE_MY_TIME_AND_GOVT_POLICY']:
        return <MyTimeVsGovtDetailsPage auditData={getExecutionResponse?.policyComparisonData?.result ?? ''} />;
      default:
        return <H2 textAlign={'center'}>Sorry!! No compare option selected</H2>;
    }
  };

  const isFailureStatus = (): boolean => {
    return (
      getExecutionResponse?.executionStatus === ExecutionStatus.COMPARISON_FAILED ||
      getExecutionResponse?.executionStatus === ExecutionStatus.POLICY_COLLECTION_FAILED
    );
  };

  // TODO: Showing only first policy details for now.
  return (
    <Container>
      {isLoading ? (
        <Spinner size={SpinnerSize.Large} />
      ) : (
        <>
          <Row gridGap={'S300'} justifyContent={'space-between'} margin={{ top: 'S500' }}>
            <Row padding={'S100'} gridGap={'S200'} alignItems={'center'}>
              <IconDocument size={IconSize.Large} color={'primary50'} aria-hidden={true} />
              <H1>{groupBasedPoliciesMap ? `${groupBasedPoliciesMap[ComparisonGroupType.A]?.[0]?.policyName}` : ''}</H1>
              <Chip backgroundColor={ChipBackgroundColor.Blue} size={ChipSize.Small}>
                {(groupBasedPoliciesMap && groupBasedPoliciesMap[ComparisonGroupType.A]?.[0]?.domain) ?? 'NA'}
              </Chip>
              <Chip
                backgroundColor={isFailureStatus() ? ChipBackgroundColor.Red : ChipBackgroundColor.Green}
                size={ChipSize.Small}
              >
                {getExecutionResponse?.executionStatus}
              </Chip>
            </Row>
            <Row gridGap={'S200'} alignItems={'center'}>
              <Row gridGap={'S200'} justifyContent={'right'}>
                <Col>
                  {/* <Select
                    value={compareOptions}
                    options={Object.values(policyComparisonToggleMap)}
                    onChange={setCompareOptions}
                  /> */}
                </Col>
              </Row>
            </Row>
          </Row>
          <Col gridGap={'S300'}>
            <Card>
              <Row gridGap={'S300'} justifyContent={'space-between'} width={'100%'}>
                <Col alignItems={'center'}>
                  <Text fontSize={'T200'} fontWeight={'bold'}>
                    Group A Source
                  </Text>
                  {groupBasedPoliciesMap &&
                    groupBasedPoliciesMap[ComparisonGroupType.A]?.map((policyContentData) => {
                      return (
                        <Link
                          href={policyContentData.originalFilePath}
                          target={'_blank'}
                          key={policyContentData.policyId}
                        >
                          {`${policyContentData.policyName} [${policyContentData.type}]`}
                        </Link>
                      );
                    })}
                </Col>
                <Col alignItems={'center'}>
                  <Text fontSize={'T200'} fontWeight={'bold'}>
                    Group B Source
                  </Text>
                  {groupBasedPoliciesMap &&
                    groupBasedPoliciesMap[ComparisonGroupType.B]?.map((policyContentData) => {
                      return (
                        <Link
                          href={policyContentData.originalFilePath}
                          target={'_blank'}
                          key={policyContentData.policyId}
                        >
                          {`${policyContentData.policyName} [${policyContentData.type}]`}
                        </Link>
                      );
                    })}
                </Col>
                <Col alignItems={'center'}>
                  <Text fontSize={'T200'} fontWeight={'bold'}>
                    Requested at
                  </Text>
                  <Text>{getExecutionResponse?.createdAt?.toLocaleString()}</Text>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col margin={{ top: 'S400' }}>{renderContentBasedOnExecutionStatus()}</Col>
        </>
      )}
    </Container>
  );
};

export default DetailsPage;
