import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getData } from 'src/clients';
import { GetReviewsRequest, GetReviewsResponse } from 'src/models';
import { GET_REVIEWS_API } from 'src/paths';

export const useGetReviews = ({
  actorRole,
  reviewStatus,
  approverReviewAssignmentStatus,
}: GetReviewsRequest): UseQueryResult<GetReviewsResponse> => {
  const path = GET_REVIEWS_API(actorRole, approverReviewAssignmentStatus, reviewStatus);
  return useQuery<unknown, unknown, GetReviewsResponse>(
    ['get-reviews', actorRole],
    () =>
      getData<GetReviewsResponse>({
        path,
      }),
    {
      enabled: !!actorRole,
    },
  );
};
