import { useState } from 'react';
import { useReviewSubmissionWorkflowContext } from 'src/components';
import { Level } from 'src/models';

const useReviewerManager = () => {
  const { levels, setLevels, needByDate, setNeedByDate } = useReviewSubmissionWorkflowContext();
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const addLevel = (levelName: string) => {
    if (!levels.find((level) => level.levelName === levelName)) {
      setLevels([...levels, { levelName, reviewers: [] }]);
    } else {
      setErrorMessage(`Level ${levelName} already exists`);
    }
  };

  const removeLastLevel = () => {
    setLevels(levels.slice(0, levels.length - 1));
  };

  const addReviewerToLevel = (levelName: string, alias: string) => {
    if (!levelName || !alias) return;
    const newLevels = levels.map((level: Level) => {
      if (level.levelName === levelName) {
        if (level.reviewers.includes(alias)) {
          setErrorMessage(`Reviewer ${alias} already present in ${levelName}`);
          return level;
        }
        return { ...level, reviewers: [...level.reviewers, alias] };
      }
      return level;
    });
    setLevels(newLevels);
  };

  const removeReviewerFromLevel = (levelName: string, alias: string) => {
    const newLevels = levels.map((level: Level) => {
      if (level.levelName === levelName) {
        return { ...level, reviewers: level.reviewers.filter((reviewer) => reviewer !== alias) };
      }
      return level;
    });
    setLevels(newLevels);
  };

  return {
    levels,
    addLevel,
    removeLastLevel,
    addReviewerToLevel,
    removeReviewerFromLevel,
    errorMessage,
    setErrorMessage,
    needByDate,
    setNeedByDate,
  };
};

export default useReviewerManager;
