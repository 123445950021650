import React from 'react';
import { Col } from '@amzn/stencil-react-components/layout';
import { LevelBasedReviewersCard } from 'src/components/shared';
import { ReviewerAvatarCard, usePolicyReviewWorkflowContext } from 'src/components';
import { convertString } from '../../utils';

const ReviewerActionComponent = () => {
  const { levels } = usePolicyReviewWorkflowContext();

  return (
    <Col gridGap={'S500'}>
      {levels.map(({ levelName, reviewers, reviewersWithStatus }, index) => {
        return (
          <LevelBasedReviewersCard key={levelName} levelName={levelName}>
            {!!reviewersWithStatus &&
              Object.entries(reviewersWithStatus).map(([alias, status]) => {
                return (
                  <ReviewerAvatarCard
                    alias={alias}
                    key={alias}
                    status={convertString(status.replace('APPROVER_', ''))}
                  />
                );
              })}
          </LevelBasedReviewersCard>
        );
      })}
    </Col>
  );
};
export default ReviewerActionComponent;
