import { useEffect, useState } from 'react';
import { useAuthentication } from 'src/components/Authentication';
import { UploadFileContentMetadata, useUploadFilesOnPreSignedURL } from 'src/hooks/useUploadFilesOnPresignedURL';
import { SERVICE } from 'src/paths';
import { useCreatePolicyMetadata } from 'src/hooks/useCreatePolicyMetadata';
import {
  DomainType,
  SourceType,
  PolicyMetadataAttributes,
  TrackingSources,
  CreatePolicyMetadataRequest,
} from 'src/models';

interface AttributeOptionsType {
  groupName: string;
  groupId: string;
  values: string[];
}

interface ErrorAttributes {
  isError: boolean;
  isWarning: boolean;
  message: string | undefined;
}
type FormField = 'name' | 'sourceType' | 'domain' | 'country' | 'policyMetadataAttributes' | 'trackingSources';

type FormFieldErrors = Record<FormField, ErrorAttributes>;

export const COUNTRY_OPTIONS = ['India', 'USA', 'Singapore']; // TODO: Add more countries in future.

const COUNTRY_TO_STATE_MAP: Record<string, string[]> = {
  ['USA']: ['California', 'New York', 'Washington', 'Texas', 'Florida'],
  ['India']: ['Maharashtra', 'Telangana'],
};

const JOB_CLASS_MAP: AttributeOptionsType = {
  groupName: 'Job Class',
  groupId: 'jobClasses',
  values: ['F', 'R', 'H', 'X', 'Q', 'I', 'M', 'S'],
};

const DEFAULT_ERROR_ATTRIBUTE: ErrorAttributes = {
  isError: false,
  isWarning: false,
  message: undefined,
};

export const usePolicyMetadataInputHook = (namespaceId: string) => {
  const { authenticatedUser } = useAuthentication();
  const { createPolicyMetadataState, createPolicyMetadata } = useCreatePolicyMetadata();

  const [name, setName] = useState<string>();
  const [description, setDescription] = useState<string>();
  const [sourceType, setSourceType] = useState<SourceType>();
  const [domain, setDomain] = useState<DomainType>();
  const [policyMetadataAttributes, setPolicyMetadataAttributes] = useState<PolicyMetadataAttributes>({});
  const [formFieldErrors, setFormFieldErrors] = useState<FormFieldErrors>({
    name: DEFAULT_ERROR_ATTRIBUTE,
    sourceType: DEFAULT_ERROR_ATTRIBUTE,
    domain: DEFAULT_ERROR_ATTRIBUTE,
    country: DEFAULT_ERROR_ATTRIBUTE,
    policyMetadataAttributes: DEFAULT_ERROR_ATTRIBUTE,
    trackingSources: DEFAULT_ERROR_ATTRIBUTE,
  });

  const [trackingSources, setTrackingSources] = useState<TrackingSources[]>([]);
  const [selectedAttributes, setSelectedAttributes] = useState<string[]>([]);
  const [policyMetadataAttributesOptions, setPolicyMetadataAttributesOptions] = useState<AttributeOptionsType[]>([
    JOB_CLASS_MAP,
  ]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const { uploadState, uploadFilesOnPreSignedURL } = useUploadFilesOnPreSignedURL();

  const handleInputChange = (field: 'name' | 'description', value: string) => {
    if (field === 'name') setName(value);
    if (field === 'description') setDescription(value);
  };

  const handleSourceTypeChange = (value: SourceType) => {
    setSourceType(value);
  };

  const handleDomainChange = (value: DomainType) => {
    setDomain(value);
  };

  const handleCountryChange = (value: string) => {
    setPolicyMetadataAttributes((prevAttributes) => ({
      ...prevAttributes,
      country: value,
    }));

    const states = COUNTRY_TO_STATE_MAP[value];
    if (states) {
      setPolicyMetadataAttributesOptions((prev) => [
        {
          groupName: 'State',
          groupId: 'states',
          values: states,
        },
        ...prev.filter((option) => option.groupName !== 'State'),
      ]);
    } else {
      setPolicyMetadataAttributesOptions((prev) => prev.filter((option) => option.groupName !== 'State'));
    }
  };

  const updateAttributes = (selectedValues: string[]) => {
    setSelectedAttributes(selectedValues);
    handleAttributesChange(selectedValues);
  };

  const handleAttributesChange = (selectedValues: string[]) => {
    setPolicyMetadataAttributes((prevAttributes) => {
      const newAttributes: PolicyMetadataAttributes = {
        ...prevAttributes,
        states: [],
        jobClasses: [],
      };
      selectedValues.forEach((value: string) => {
        policyMetadataAttributesOptions.forEach((option) => {
          if (option.values.includes(value)) {
            const key = option.groupId;
            if (key === 'states' || key === 'jobClasses') {
              (newAttributes[key] as string[]).push(value);
            }
          }
        });
      });

      return newAttributes;
    });
  };

  const updateFieldError = (field: FormField, errorAttributes: ErrorAttributes) => {
    setFormFieldErrors((prevErrors) => ({
      ...prevErrors,
      [field]: errorAttributes,
    }));
  };

  const resetAllErrors = () => {
    setFormFieldErrors({
      name: DEFAULT_ERROR_ATTRIBUTE,
      sourceType: DEFAULT_ERROR_ATTRIBUTE,
      domain: DEFAULT_ERROR_ATTRIBUTE,
      country: DEFAULT_ERROR_ATTRIBUTE,
      policyMetadataAttributes: DEFAULT_ERROR_ATTRIBUTE,
      trackingSources: DEFAULT_ERROR_ATTRIBUTE,
    });
  };

  const validateName = () => {
    if (!name) {
      updateFieldError('name', {
        isError: true,
        isWarning: false,
        message: 'Policy name is required. Please enter a name.',
      });
      return false;
    }
    return true;
  };

  const validateSourceType = () => {
    if (!sourceType) {
      updateFieldError('sourceType', {
        isError: true,
        isWarning: false,
        message: 'Please select a source type for the policy.',
      });
      return false;
    }
    return true;
  };

  const validateDomain = () => {
    if (!domain) {
      updateFieldError('domain', {
        isError: true,
        isWarning: false,
        message: 'Please select a domain for the policy.',
      });
      return false;
    }
    return true;
  };

  const validatePolicyMetadataAttributes = () => {
    let isPolicyMetadataAttributesValid = true;
    if (!policyMetadataAttributes.country) {
      isPolicyMetadataAttributesValid = false;
      updateFieldError('country', {
        isError: true,
        isWarning: false,
        message: 'Please select a country for the policy.',
      });
      updateFieldError('policyMetadataAttributes', {
        isError: true,
        isWarning: false,
        message: 'At least one policy attribute must be selected.',
      });
    }
    if (policyMetadataAttributes.country && COUNTRY_TO_STATE_MAP[policyMetadataAttributes.country]) {
      const { jobClasses, states } = policyMetadataAttributes;
      const hasJobClasses = jobClasses && jobClasses.length > 0;
      const hasStates = states && states.length > 0;

      if (!hasJobClasses || !hasStates) {
        isPolicyMetadataAttributesValid = false;
        let message;

        if (!hasJobClasses && !hasStates) {
          message = 'For the selected country, both state and job class must be specified.';
        } else if (!hasJobClasses) {
          message = 'For the selected country, job class must be specified.';
        } else {
          message = 'For the selected country, state must be specified.';
        }

        updateFieldError('policyMetadataAttributes', {
          isError: true,
          isWarning: false,
          message,
        });
      }
    }
    return isPolicyMetadataAttributesValid;
  };

  const validateTrackingSource = () => {
    if (sourceType == SourceType.GOVT && trackingSources.length == 0) {
      updateFieldError('trackingSources', {
        isError: true,
        isWarning: false,
        message: ' Please provide the policy link.',
      });
      return false;
    }
    return true;
  };

  const validatePolicyMetadataInputForm = () => {
    resetAllErrors();
    const isNameValid = validateName();
    const isDomainValid = validateDomain();
    const isPolicyMetadataValid = validatePolicyMetadataAttributes();
    const isSourceTypeValid = validateSourceType();
    const isTrackingSourceValid = validateTrackingSource();

    return isNameValid && isDomainValid && isPolicyMetadataValid && isSourceTypeValid && isTrackingSourceValid;
  };

  const handleSubmit = (onSubmit: () => void) => {
    if (!validatePolicyMetadataInputForm()) {
      return;
    }

    setIsSubmitting(true);
    setError(null);

    try {
      const payload: CreatePolicyMetadataRequest = {
        name: name!,
        description: description,
        sourceType: sourceType!,
        domain: domain!,
        policyMetadataAttributes: policyMetadataAttributes,
        trackingSources: trackingSources,
        namespaceId: namespaceId,
        requesterAlias: authenticatedUser?.userName || '', // TODO: Remove and move to header.
      };
      createPolicyMetadata(payload);
      onSubmit();
    } catch (err) {
      setError('Failed to create policy metadata. Please try again.');
    } finally {
      setIsSubmitting(false);
    }
  };

  const getFileContentAsString = (file: File) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event) => {
        resolve(event?.target?.result);
      };

      reader.onerror = (error) => {
        reject(error);
      };

      reader.readAsText(file);
    });
  };

  const handleLinkInput = (link: string) => {
    if (link) {
      setTrackingSources([
        {
          referenceId: link,
          sourceType: sourceType!,
        },
      ]);
    }
  };

  const handleFilesUpload = async (files: File[]) => {
    //TODO: Handle for multiple file, as currently workflow allows one upload file
    if (files.length > 0) {
      const fileToUpload = files[0];
      const { name } = fileToUpload;
      const content = await getFileContentAsString(fileToUpload).then();
      uploadFilesOnPreSignedURL([
        {
          fileContent: content,
          fileName: name,
          service: SERVICE.PMS,
          namespaceId: namespaceId,
        } as UploadFileContentMetadata,
      ]);
    }
  };

  const updateTrackingSourceWithFile = () => {
    if (uploadState.data && uploadState.data.length > 0) {
      const referenceId: string = uploadState.data[0][0];
      setTrackingSources((prevSources) => {
        const newSources = {
          referenceId: referenceId,
          sourceType: sourceType,
        } as TrackingSources;
        return [...prevSources, newSources];
      });
    }
  };
  useEffect(() => {
    updateTrackingSourceWithFile();
  }, [uploadState.data]);

  return {
    name,
    description,
    sourceType,
    domain,
    policyMetadataAttributesOptions,
    policyMetadataAttributes,
    trackingSources,
    formFieldErrors,
    handleInputChange,
    handleCountryChange,
    handleSourceTypeChange,
    selectedAttributes,
    updateAttributes,
    handleDomainChange,
    handleAttributesChange,
    handleSubmit,
    handleFileUpload: handleFilesUpload,
    handleLinkInput,
    isSubmitting,
    error,
  };
};
