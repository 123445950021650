import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getData } from 'src/clients';
import { GetNamespacesResponse } from 'src/models';

// TODO: add actual API call to get namespaces when API is ready.
export const useGetNamespaces = (): GetNamespacesResponse => {
  return {
    namespaces: [
      {
        namespaceId: 'Pay-Policies-Namespace',
        namespaceVersion: 1,
      },
    ],
  };
};
