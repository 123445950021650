import React from 'react';
import { BaseAvatarCard } from 'src/components/shared/reviewers-avatar-component';
import { Col, Row } from '@amzn/stencil-react-components/layout';
import { Button, ButtonIconPosition } from '@amzn/stencil-react-components/button';
import { IconBin } from '@amzn/stencil-react-components/icons';

interface RequesterActionableAvatarCardProps {
  alias: string;
  onRemove: (levelName: string, alias: string) => void;
  levelName: string;
}

const RequesterActionableAvatarCard: React.FC<RequesterActionableAvatarCardProps> = ({
  alias,
  onRemove,
  levelName,
}) => {
  // TODO: Remove the hardcoded full name and role after PAPI integration.
  return (
    <BaseAvatarCard userInfo={{ alias }}>
      <Col margin={'S200'} alignItems={'center'}>
        <Row gridGap={'S300'} justifyContent={'center'}>
          <Button
            isDestructive
            icon={<IconBin />}
            iconPosition={ButtonIconPosition.Trailing}
            onClick={() => onRemove(levelName, alias)}
          >
            Remove
          </Button>
        </Row>
      </Col>
    </BaseAvatarCard>
  );
};

export default RequesterActionableAvatarCard;
