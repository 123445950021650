import { useQueries, useQuery } from '@tanstack/react-query';
import { fetchDataFromPreSignedUrl } from 'src/clients';

export type UseFetchS3ContentOptions = {
  preSignedUrls: string[]; // List of preSigned URLs
};

export const useFetchS3Contents = ({ preSignedUrls }: UseFetchS3ContentOptions) => {
  return useQueries({
    queries: preSignedUrls.map((url) => ({
      queryKey: ['preSignedUrl', url],
      queryFn: () => fetchDataFromPreSignedUrl(url),
      enabled: !!url, // Only enable query if the URL exists
      staleTime: 1000 * 60, // Cache for 1 minute
      retry: true,
      retryDelay: (attempt: number) => Math.min(1000 * 2 ** attempt, 10000), // Exponential backoff
    })),
  });
};
