import React, { useContext } from 'react';
import { Flex, Row } from '@amzn/stencil-react-components/layout';
import { AvatarSize, PersonalDetailsAvatar } from '@amzn/stencil-react-components/avatar';
import { Text } from '@amzn/stencil-react-components/text';
import { getBorderColor } from 'src/components/utils';
import { Chip, ChipBackgroundColor, ChipSize } from '@amzn/stencil-react-components/chip';
import { differenceInDays, isBefore } from 'date-fns';
import {
  IconAlertCircleFill,
  IconAlertTriangleFill,
  IconCalendar,
  IconSize,
} from '@amzn/stencil-react-components/icons';
import { Badge, BadgeType } from '@amzn/stencil-react-components/badge';
import { Link } from '@amzn/stencil-react-components/link';
import { NUMBER_OF_MILLISECONDS_IN_A_SECOND } from 'src/utils';

export const TitleText = ({ data }: { data: any }) => {
  return <Text>{data?.title}</Text>;
};

export const PolicyText = ({ data }: { data: any }) => {
  const policyName = data?.policies?.[0]?.name || '';
  const policyCount = data?.policies?.length || 0;

  return (
    <Row gridGap={5} padding="S200" alignItems="center">
      <Text>{policyName}</Text>
      <Badge value={policyCount} type={BadgeType.Positive} />
    </Row>
  );
};

//TODO: Need confirmation executionId is clickable link or click on row to redirect
export const ExecutionIdText = ({ data }: { data: any }) => {
  return <Text>{data?.executionId}</Text>;
};

export const VersionComponent = ({ data }: { data: any }) => {
  return (
    <Row padding="S200" alignItems="center">
      <Badge value={data?.version} type={BadgeType.Primary} />
    </Row>
  );
};

export const ExecutionVersionComponent = ({ data }: { data: any }) => {
  return (
    <Row padding="S200" alignItems="center">
      <Badge value={data?.executionVersion} type={BadgeType.Primary} />
    </Row>
  );
};

export const AvatarCell = ({ data }: { data: any }) => (
  <PersonalDetailsAvatar username={data?.requesterAlias} size={AvatarSize.Small} />
);

export const DateText = ({ data }: { data: any }) => {
  const dateObject = new Date(
    typeof data.createdAt === 'string' ? data?.createdAt : data?.createdAt * NUMBER_OF_MILLISECONDS_IN_A_SECOND,
  ); // TODO: standardise date based data in API response and move logic of date conversion at API layer
  return (
    <Flex gridGap="S200" flexDirection="row">
      <Text fontSize="T100">
        {dateObject.toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' })}
      </Text>
      <Text fontSize="T100">
        {dateObject.toLocaleTimeString('en-US', { hour: 'numeric', hour12: true, minute: 'numeric' })}
      </Text>
    </Flex>
  );
};

const formatStatus = (status: string): string => {
  return status
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(' ');
};

export const ColoredText = ({ data }: { data: any }) => {
  return (
    <Text color={getBorderColor(data?.reviewStatus || data?.executionStatus)}>
      {formatStatus(data?.reviewStatus || data?.executionStatus)}
    </Text>
  );
};

interface DateStatusInfo {
  background: ChipBackgroundColor;
  icon: JSX.Element;
}

function getDateStatusInfo(unixTimestamp: number): DateStatusInfo {
  const today = new Date();
  const dueDate = new Date(unixTimestamp);
  if (isBefore(dueDate, today)) {
    return {
      background: ChipBackgroundColor.Red,
      icon: <IconAlertCircleFill size={IconSize.ExtraSmall} color="red70" />,
    };
  }

  const daysDifference = differenceInDays(dueDate, today);

  if (daysDifference < 5) {
    return {
      background: ChipBackgroundColor.Yellow,
      icon: <IconAlertTriangleFill size={IconSize.ExtraSmall} color="yellow70" />,
    };
  }

  return {
    background: ChipBackgroundColor.Default,
    icon: <IconCalendar color="neutral70" />,
  };
}

export const DateTextBanner = ({ data }: { data: any }) => {
  const { background, icon } = getDateStatusInfo(data?.needByDate * NUMBER_OF_MILLISECONDS_IN_A_SECOND);
  const dateObject = new Date(data?.needByDate * NUMBER_OF_MILLISECONDS_IN_A_SECOND);
  return (
    <Chip size={ChipSize.Small} backgroundColor={background} icon={icon}>
      <Flex gridGap="S200" flexDirection="row">
        <Text fontSize="T100">
          {dateObject.toLocaleDateString('en-US', { day: 'numeric', month: 'short', year: 'numeric' })}
        </Text>
        <Text fontSize="T100">
          {dateObject.toLocaleTimeString('en-US', { hour: 'numeric', hour12: true, minute: 'numeric' })}
        </Text>
      </Flex>{' '}
    </Chip>
  );
};
