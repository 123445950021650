import { useState, useEffect } from 'react';
import { useGetReviews } from 'src/hooks/useGetReviews';
import { ActorRole, GetReviewsResponse } from 'src/models';
import { useGetExecutions } from 'src/hooks/useGetExecutionsHook';
import { GetExecutionsResponse } from 'src/models/api-models/getExecutions';

interface TableCounts {
  reviewsRequestedCount: number;
  executionCount: number;
  needToReviewCount: number;
}

interface DashboardData {
  reviewsRequested: {
    data: GetReviewsResponse | undefined;
    isLoading: boolean;
    error: any;
  };
  assignedReviews: {
    data: GetReviewsResponse | undefined;
    isLoading: boolean;
    error: any;
  };
  executions: {
    data: GetExecutionsResponse | undefined;
    isLoading: boolean;
    error: any;
  };
  counts: TableCounts;
}

const DEFAULT_TILE_COUNT = 0;

export const useGetDashboardData = (): DashboardData => {
  const [counts, setCounts] = useState<TableCounts>({
    reviewsRequestedCount: DEFAULT_TILE_COUNT,
    executionCount: DEFAULT_TILE_COUNT,
    needToReviewCount: DEFAULT_TILE_COUNT,
  });

  const {
    data: reviewsRequestedData,
    isLoading: isReviewsRequestedLoading,
    error: reviewsRequestedError,
  } = useGetReviews({
    actorRole: ActorRole.REQUESTER,
  });

  const {
    data: assignedReviews,
    isLoading: isAssignedReviewsLoading,
    error: assignedReviewsError,
  } = useGetReviews({
    actorRole: ActorRole.APPROVER,
  });

  const { data: executionsData, isLoading: isExecutionsLoading, error: executionsError } = useGetExecutions();

  useEffect(() => {
    if (reviewsRequestedData?.reviews || assignedReviews?.reviews || executionsData?.executions) {
      const newCounts: TableCounts = {
        reviewsRequestedCount: reviewsRequestedData?.reviews.length || DEFAULT_TILE_COUNT,
        executionCount: executionsData?.executions.length || DEFAULT_TILE_COUNT,
        needToReviewCount: assignedReviews?.reviews.length || DEFAULT_TILE_COUNT,
      };

      setCounts(newCounts);
    }
  }, [assignedReviews, reviewsRequestedData, executionsData]);

  return {
    reviewsRequested: {
      data: reviewsRequestedData,
      isLoading: isReviewsRequestedLoading,
      error: reviewsRequestedError,
    },
    assignedReviews: {
      data: assignedReviews,
      isLoading: isAssignedReviewsLoading,
      error: assignedReviewsError,
    },
    executions: {
      data: executionsData,
      isLoading: isExecutionsLoading,
      error: executionsError,
    },
    counts,
  };
};
