import { useCurrentStage } from 'src/hooks';
import { useMutation } from '@tanstack/react-query';
import { PAPI } from 'src/clients/papi-client';
import { REGION } from 'src/constants';
import { SearchEmployeeRecord } from 'src/models';
import { HttpError } from 'src/clients';

export interface SearchEmployeeResult {
  nextToken?: string;
  records: SearchEmployeeRecord[];
}

export const useSearchEmployees = () => {
  const stage = useCurrentStage();
  const { data, isLoading, isError, error, mutate } = useMutation<unknown, HttpError, { searchTerm: string }>(
    async ({ searchTerm }) => {
      const papiClient = await PAPI.getInstance(REGION.PDX, stage);
      return papiClient.searchEmployeeV2({ searchTerm: searchTerm, nextToken: undefined, size: 50 });
    },
  );

  const searchEmployees = (searchTerm: string) => {
    mutate({ searchTerm });
  };

  return {
    data: data as SearchEmployeeResult,
    isLoading,
    isError,
    error,
    searchEmployees,
  };
};
