import React, { ReactNode, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { POST_AUTH_REDIRECT_STORAGE_ITEM } from 'src/constants/SessionConstants';

export const PostAuthenticationController = (props: { children?: ReactNode }) => {
  const [isRedirecting, setIsRedirecting] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const postAuthRedirect = window.sessionStorage.getItem(POST_AUTH_REDIRECT_STORAGE_ITEM);
    if (postAuthRedirect) {
      window.sessionStorage.removeItem(POST_AUTH_REDIRECT_STORAGE_ITEM);
      navigate(postAuthRedirect);
    }
    setIsRedirecting(false);
  }, []);

  // We only render children when redirecting is complete to avoid unclean lifecycle
  // of the useBundle hook for form page localization
  return isRedirecting ? null : <>{props.children}</>;
};
