import React from 'react';
import { DiffViewerComponent } from 'src/components/shared/diff-viewer-component/index';
import { Col } from '@amzn/stencil-react-components/layout';
import { H3 } from '@amzn/stencil-react-components/text';

const PolicyDiffViewerComponent: React.FC<{ oldHtmlData: string; newHtmlData: string }> = ({
  oldHtmlData,
  newHtmlData,
}) => {
  return (
    <Col gridGap={'S300'} width={'100%'}>
      <H3>Policy Diff View</H3>
      <DiffViewerComponent oldData={oldHtmlData} newData={newHtmlData} />
    </Col>
  );
};

export default PolicyDiffViewerComponent;
