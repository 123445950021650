import React, { ReactNode } from 'react';
import { Amplify, ResourcesConfig } from 'aws-amplify';
import { AuthenticationContext } from './AuthenticationContext';
import { environmentConfiguration } from './AmplifyConfig';

// Destructing values from environment configuration
const {
  userPoolId: userPoolId,
  identityPoolId: identityPoolId,
  userPoolClientId: clientId,
  domain: domainName,
  userPoolAuthScope: userPoolAuthScope,
  userPoolAuthResponseType: userPoolAuthResponseType,
  userPoolIdp: userPoolIdp,
} = environmentConfiguration;

const amplifyConfig: ResourcesConfig = {
  Auth: {
    Cognito: {
      userPoolId: userPoolId,
      userPoolClientId: clientId,
      identityPoolId: identityPoolId,
      loginWith: {
        oauth: {
          domain: domainName,
          scopes: userPoolAuthScope,
          redirectSignIn: [`${window.location.origin}`],
          redirectSignOut: [`${window.location.origin}`],
          responseType: userPoolAuthResponseType,
          providers: [{ custom: userPoolIdp }],
        },
      },
    },
  },
};

/**
 * Authentication provider to initialize Amplify and provide authentication context.
 * @param props React children components.
 * @returns Authentication context wrapper.
 */
export const AuthenticationProvider = (props: { children?: ReactNode }) => {
  Amplify.configure(amplifyConfig); // Configure Amplify with environment-specific settings

  return <AuthenticationContext>{props.children}</AuthenticationContext>;
};
