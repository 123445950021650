import React, { useEffect, useState } from 'react';
import { BaseAvatarCard } from 'src/components/shared/reviewers-avatar-component';
import { Col, Row } from '@amzn/stencil-react-components/layout';
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import { Modal, ModalContent } from '@amzn/stencil-react-components/modal';
import { Input } from '@amzn/stencil-react-components/form';
import { useAuthentication, usePolicyReviewWorkflowContext } from 'src/components';
import { ReviewApproverAction } from 'src/models';
import { Chip } from '@amzn/stencil-react-components/chip';
import { getBGColor } from 'src/components/policy-review-workflow/utils';
import { Spinner } from '@amzn/stencil-react-components/spinner';

const ReviewerAvatarCard: React.FC<{ alias: string; status: string }> = ({ alias, status }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [action, setAction] = useState<ReviewApproverAction | undefined>(ReviewApproverAction.INVALIDATE_APPROVAL);
  const [justification, setJustification] = useState<string | undefined>('');
  const { reviewerAction, executionVersion, executionId, reviewerActionState, getReview } =
    usePolicyReviewWorkflowContext();
  const { authenticatedUser } = useAuthentication();

  const openModal = (actionType: ReviewApproverAction) => {
    setIsModalOpen(true);
    setAction(actionType);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setAction(undefined);
    setJustification(undefined);
  };

  const getAction = (action: ReviewApproverAction): ReviewApproverAction => {
    if (!justification) return action;
    else {
      return action === ReviewApproverAction.APPROVE ? ReviewApproverAction.APPROVE_WITH_CONDITION : action;
    }
  };

  const handleSubmitAction = () => {
    if (!action) return;
    closeModal();
    reviewerAction({
      executionId,
      executionVersion,
      action: getAction(action),
      comment: justification ? justification : undefined,
    });
  };

  // Re-fetch review to ensure state change for approver status update
  useEffect(() => {
    if (!reviewerActionState.error && reviewerActionState.data?.reviewId) getReview();
  }, [reviewerActionState.data, reviewerActionState.error]);

  return (
    <>
      <Modal aria-labelledby="stencil-modal-title" isOpen={isModalOpen} close={closeModal}>
        <ModalContent
          titleText="Provide Justification [Optional]"
          buttons={[
            <Button onClick={handleSubmitAction} key={'action-button'} variant={ButtonVariant.Primary}>
              {action}
            </Button>,
          ]}
        >
          <Input
            width={'100%'}
            autoFocus={true}
            id="take-action-justification-box-id"
            type="text"
            value={justification}
            onChange={(event) => {
              setJustification(event.target.value);
            }}
          />
        </ModalContent>
      </Modal>
      <BaseAvatarCard userInfo={{ alias }}>
        {reviewerActionState.loading ? (
          <Spinner />
        ) : (
          <Col margin={'S200'} alignItems={'center'}>
            {alias === authenticatedUser?.userName && status === 'Pending' ? (
              <Row gridGap={'S300'} justifyContent={'center'}>
                <Button aria-describedby="sroDialog" onClick={() => openModal(ReviewApproverAction.APPROVE)}>
                  Accept
                </Button>
                <Button
                  isDestructive
                  aria-describedby="sroDialog"
                  onClick={() => openModal(ReviewApproverAction.REJECT)}
                >
                  Reject
                </Button>
              </Row>
            ) : (
              <Row gridGap={'S300'} justifyContent={'center'}>
                <Chip backgroundColor={getBGColor(status)}>{status}</Chip>
              </Row>
            )}
          </Col>
        )}
      </BaseAvatarCard>
    </>
  );
};

export default ReviewerAvatarCard;
