import React from 'react';
import { Card } from '@amzn/stencil-react-components/card';
import { Checkbox, FormWrapper, InputHeader, Select } from '@amzn/stencil-react-components/form';
import { Col, Container, Flex, GridContainer, GridItem, Row, Spacer } from '@amzn/stencil-react-components/layout';
import { H2, H3, Label, Text } from '@amzn/stencil-react-components/text';
import { Spinner, SpinnerSize } from '@amzn/stencil-react-components/spinner';
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner';
import { ComparisonGroupType } from 'src/models';
import { PolicyMetadataInputForm } from 'src/components/shared/policy-metadata-form';
import { Button } from '@amzn/stencil-react-components/button';
import { Modal } from '@amzn/stencil-react-components/modal';
import { IconPlus } from '@amzn/stencil-react-components/icons';
import { useCreateExecutionWorkflowContext } from '../context';
import { PolicyTile } from '../shared';

const POLICY_LIST_ERROR_MESSAGE = 'Unable to load polices in a namespace due to some error.';
const POLICY_LIST_EMPTY_MESSAGE = 'No Policies found for the selected namespace.';
const INFORMATIONAL_MESSAGE =
  'Please select distinct policies for Group A and Group B. The same policy cannot be selected in both groups.';

export const SelectPoliciesStep: React.FC = () => {
  const {
    namespaceOptions,
    selectedNamespaceId,
    handleNamespaceIdSelection,
    getPoliciesMetadataState,
    selectPoliciesValidationError,
    toggleMyTimeCheckbox,
    compareWithMyTimeCheckbox,
    handleCompareWithMyTimeCheckboxUpdate,
    isModalOpen,
    openModal,
    closeModal,
    onModalSubmit,
  } = useCreateExecutionWorkflowContext();

  const renderPoliciesContent = () => {
    if (!selectedNamespaceId) {
      return null;
    }

    if (getPoliciesMetadataState.loading) {
      return (
        <Row width={'100%'} justifyContent={'center'}>
          <Spinner size={SpinnerSize.Small} />
        </Row>
      );
    }

    if (getPoliciesMetadataState.error) {
      return <MessageBanner type={MessageBannerType.Error}>{POLICY_LIST_ERROR_MESSAGE}</MessageBanner>;
    }

    if (!getPoliciesMetadataState.data || getPoliciesMetadataState.data.policiesMetadata.length === 0) {
      return <MessageBanner type={MessageBannerType.Warning}>{POLICY_LIST_EMPTY_MESSAGE}</MessageBanner>;
    }

    return (
      <>
        {!selectPoliciesValidationError && (
          <MessageBanner type={MessageBannerType.Informational}>
            <Text>{INFORMATIONAL_MESSAGE}</Text>
          </MessageBanner>
        )}
        <GridContainer>
          <GridItem lg={6} md={4} sm={2}>
            <Card width={'100%'}>
              <Col gridGap={'S400'} width={'100%'}>
                <Col height={'35rem'} overflow={'scroll'}>
                  <Row margin={{ left: '0.8rem' }}>
                    <H3>Group {ComparisonGroupType.A}</H3>
                  </Row>
                  {getPoliciesMetadataState.data.policiesMetadata.map((policyMetadata) => (
                    <PolicyTile
                      group={ComparisonGroupType.A}
                      key={policyMetadata.policyId}
                      policyMetadata={policyMetadata}
                    />
                  ))}
                </Col>
              </Col>
            </Card>
          </GridItem>
          <GridItem lg={6} md={4} sm={2}>
            <Card width={'100%'}>
              <Col gridGap={'S400'} width={'100%'}>
                <Col height={'35rem'} overflow={'scroll'}>
                  <Row margin={{ left: '0.8rem' }}>
                    <H3>Group {ComparisonGroupType.B}</H3>
                  </Row>
                  {getPoliciesMetadataState.data.policiesMetadata.map((policyMetadata) => (
                    <PolicyTile
                      viewMode={compareWithMyTimeCheckbox}
                      group={ComparisonGroupType.B}
                      key={policyMetadata.policyId}
                      policyMetadata={policyMetadata}
                    />
                  ))}
                </Col>
              </Col>
            </Card>
          </GridItem>
        </GridContainer>
        <Row gridGap="S200" alignItems="center">
          <Checkbox
            disabled={toggleMyTimeCheckbox()}
            name="my-time-checkbox"
            id="my-time-checkbox"
            onChange={handleCompareWithMyTimeCheckboxUpdate}
            checked={compareWithMyTimeCheckbox}
          />
          <Label htmlFor="my-time-checkbox">Compare Group A policies with its corresponding My Time policy.</Label>
        </Row>
      </>
    );
  };

  return (
    <Container>
      <FormWrapper>
        <Row justifyContent={'space-between'}>
          <Row width={'80%'}>
            <H2>Workspace</H2>
            <Spacer width={'S400'} />
            <Select
              id="namespace-input"
              placeholder="Select workspace to start with execution request."
              options={namespaceOptions}
              value={selectedNamespaceId}
              onChange={(value) => handleNamespaceIdSelection(value)}
            />
          </Row>
          <Row>
            <Button
              disabled={!selectedNamespaceId}
              icon={<IconPlus />}
              aria-describedby="sroDialog"
              onClick={openModal}
            >
              Additional Documents
            </Button>
            <Modal isOpen={isModalOpen} close={closeModal}>
              <PolicyMetadataInputForm onSubmit={onModalSubmit} namespaceId={selectedNamespaceId!} />
            </Modal>
          </Row>
        </Row>
        {selectPoliciesValidationError && (
          <MessageBanner type={MessageBannerType.Error}>
            <Text>{selectPoliciesValidationError}</Text>
          </MessageBanner>
        )}
        {renderPoliciesContent()}
      </FormWrapper>
    </Container>
  );
};
