import React from 'react';
import { Card } from '@amzn/stencil-react-components/card';
import { H2, Text } from '@amzn/stencil-react-components/text';
import { IconCrossInsideCircle, IconSize, IconTick } from '@amzn/stencil-react-components/icons';
import { Col } from '@amzn/stencil-react-components/layout';
import { Spinner } from '@amzn/stencil-react-components/spinner';
import { Link } from '@amzn/stencil-react-components/link';
import { useNavigate } from 'react-router-dom';
import { POLICY_DETAILS } from 'src/constants/RoutesConstants';
import { useCreateExecutionWorkflowContext } from '../context';

export const CreateExecutionComplete = () => {
  const { createExecutionState, submittingExecution } = useCreateExecutionWorkflowContext();
  const navigate = useNavigate();
  return (
    <>
      {createExecutionState.loading || submittingExecution ? (
        <Spinner />
      ) : (
        <Card justifyContent={'center'}>
          <Col gridGap={'S300'} alignItems={'center'} padding={'S300'}>
            {createExecutionState.error && !createExecutionState.data ? (
              <>
                <H2>Execution Request Submission Failed</H2>
                <Text>
                  <IconCrossInsideCircle size={IconSize.Large} color={'red70'} />
                </Text>
                <Text>Execution Request Submission Failed with error : {`${createExecutionState.error}`}</Text>
              </>
            ) : (
              <>
                <H2>Execution Request Submitted</H2>
                <Text>
                  <IconTick size={IconSize.Large} color={'green70'} />
                </Text>

                <Text>
                  Execution Request Submitted with execution id :
                  <Link
                    onClick={() =>
                      navigate(
                        `${POLICY_DETAILS}/${createExecutionState?.data?.executionId}/version/${createExecutionState?.data?.version}`,
                      )
                    }
                  >
                    {` ${createExecutionState?.data?.executionId}`}
                  </Link>
                </Text>
              </>
            )}
          </Col>
        </Card>
      )}
    </>
  );
};
