/**
 * Converts a plain text string to HTML by replacing special characters with their
 * corresponding HTML entities and converting newline characters to <br> tags.
 *
 * @param {string} text - The plain text string to be converted.
 * @returns {string} The converted HTML string.
 */
export const convertTextToHtml = (text: string): string => {
  const htmlEscapeRegex = /[&<>"']/g;
  const newlineRegex = /\n/g;

  return text
    .replace(htmlEscapeRegex, (match) => {
      switch (match) {
        case '&':
          return '&amp;';
        case '<':
          return '&lt;';
        case '>':
          return '&gt;';
        case '"':
          return '&quot;';
        case "'":
          return '&#039;';
        default:
          return match;
      }
    })
    .replace(newlineRegex, '<br>');
};
