import React from 'react';
import { FormWrapper, InputWrapper, TextArea } from '@amzn/stencil-react-components/form';
import { Col, Container } from '@amzn/stencil-react-components/layout';
import { H3 } from '@amzn/stencil-react-components/text';
import { Card } from '@amzn/stencil-react-components/card';
import { useCreateExecutionWorkflowContext } from '../context';
import { ParameterTile } from '../shared';
import { parameterOptions } from '../hooks';

export const SelectSpecificationsStep = () => {
  const { additionalInstructions, handleAdditionalInstructionsUpdate } = useCreateExecutionWorkflowContext();

  const Parameters = () => {
    return (
      <FormWrapper>
        {parameterOptions.map((parameter) => (
          <ParameterTile key={parameter.id} viewMode={false} parameter={parameter} />
        ))}
      </FormWrapper>
    );
  };

  return (
    <Container>
      <FormWrapper>
        <Card width={'100%'}>
          <Col width={'100%'}>
            <InputWrapper id="additional-parameters-select" labelText="Parameters" renderLabel={renderCustomLabel}>
              {(inputProps) => <Parameters {...inputProps} />}
            </InputWrapper>
          </Col>
        </Card>
        <Card width={'100%'}>
          <Col width={'100%'}>
            <InputWrapper
              id="additional-instruction-input"
              labelText="Additional Instructions"
              renderLabel={renderCustomLabel}
            >
              {(inputProps) => (
                <TextArea
                  value={additionalInstructions}
                  onChange={(event) => handleAdditionalInstructionsUpdate(event.target.value)}
                  {...inputProps}
                  resize={'vertical'}
                />
              )}
            </InputWrapper>
          </Col>
        </Card>
      </FormWrapper>
    </Container>
  );
};

const renderCustomLabel = ({ children }: any) => {
  return <H3>{children}</H3>;
};
