import React from 'react';
import { deserializeFromMarkdown, HtmlRenderer } from '@amzn/stencil-react-components/rte';
import { Col, Row } from '@amzn/stencil-react-components/layout';
import { ProgressBar, ProgressBarStatus } from '@amzn/stencil-react-components/progress-bar';
import { Status, StatusIndicator } from '@amzn/stencil-react-components/status-indicator';
import { H2 } from '@amzn/stencil-react-components/text';
import { Card } from '@amzn/stencil-react-components/card';

const MyTimeVsGovtDetailsPage = ({ auditData }: { auditData: string }) => {
  return (
    <Col gridGap={'S200'}>
      <H2>MyTime & Govt Policy Comparison</H2>
      <Card justifyContent={'center'} margin={{ top: 'S300' }}>
        <Col width={'100%'}>
          <HtmlRenderer html={deserializeFromMarkdown(auditData) || ''} />
        </Col>
      </Card>
    </Col>
  );
};

export default MyTimeVsGovtDetailsPage;
