import React, { createContext, useContext } from 'react';
import { HttpError } from 'src/clients';
import {
  ComparisonGroupType,
  CreateExecutionRequest,
  CreateExecutionResponse,
  GetPoliciesMetadataResponse,
  PolicyMetadata,
} from 'src/models';
import { ParameterType, SelectedPoliciesType } from '../hooks';

// TODO: Add more fields as we create/add steps.
export interface CreateExecutionWorkflowContextType {
  selectedNamespaceId: string | undefined;
  namespaceOptions: string[];
  handleNamespaceIdSelection: (namespaceId: string) => void;
  createExecution: (createExecution: CreateExecutionRequest) => void;
  createExecutionState: {
    data: CreateExecutionResponse;
    loading: boolean;
    error: HttpError | null;
    retry: () => void;
  };
  getPoliciesMetadataState: {
    data: GetPoliciesMetadataResponse;
    loading: boolean;
    error: HttpError | null;
    retry: () => void;
  };
  selectedPolicies: SelectedPoliciesType;
  updateSelectedPolicies: (group: ComparisonGroupType, policy: PolicyMetadata, isSelected: boolean) => void;
  selectPoliciesValidationError: string | undefined;
  additionalInstructions: string | undefined;
  handleAdditionalInstructionsUpdate: (instructions: string) => void;
  selectedParameters: ParameterType[] | undefined;
  handleParameterChange: (parameter: ParameterType, isChecked: boolean) => void;
  toggleMyTimeCheckbox: () => boolean;
  compareWithMyTimeCheckbox: boolean;
  handleCompareWithMyTimeCheckboxUpdate: () => void;
  submittingExecution: boolean;
  isModalOpen: boolean;
  openModal: () => void;
  closeModal: () => void;
  onModalSubmit: () => void;
}

export const CreateExecutionWorkflowContext = createContext<CreateExecutionWorkflowContextType | undefined>(undefined);

export const CreateExecutionWorkflowContextProvider: React.FC<{
  createExecutionWorkflowInitial: CreateExecutionWorkflowContextType;
  children: React.ReactNode;
}> = ({ createExecutionWorkflowInitial, children }) => {
  return (
    <CreateExecutionWorkflowContext.Provider
      value={{
        ...createExecutionWorkflowInitial,
      }}
    >
      {children}
    </CreateExecutionWorkflowContext.Provider>
  );
};

export const useCreateExecutionWorkflowContext = () => {
  const context = useContext(CreateExecutionWorkflowContext);
  if (!context) {
    throw new Error('useCreateExecutionWorkflowContext must be used within a CreateExecutionWorkflowContextProvider');
  }
  return context;
};
