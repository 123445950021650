import React, { useState } from 'react';
import { Container } from '@amzn/stencil-react-components/layout';
import { ManageReviewersStep } from 'src/components/review-submission-workflow/steps';
import {
  EditedPolicyViewerStep,
  ReviewPolicyReviewDetailsStep,
  ReviewSubmissionComplete,
  ReviewSubmissionWorkflowContextProvider,
  WorkflowStepsContainer,
} from 'src/components';
import { useCreateReview, usePolicyDetails } from 'src/hooks';
import { useNavigate } from 'react-router-dom';
import { getEpochFromDateString } from 'src/utils';
import { Level } from 'src/models';
import { useParams } from 'react-router';

const ReviewSubmissionWorkflow = () => {
  const navigate = useNavigate();
  const { executionId, version } = useParams<{ executionId: string; version: string }>();
  const [levels, setLevels] = useState<Level[]>([
    {
      levelName: 'Business',
      reviewers: ['akschad'],
    },
    {
      levelName: 'Legal',
      reviewers: ['sachgary'],
    },
  ]);
  const [needByDate, setNeedByDate] = useState<string>('');

  const {
    responseState: { getExecutionResponse: getExecutionResponse, policyContentData: policyContentData },
    isLoading,
    isError,
    error,
  } = usePolicyDetails(executionId!, Number(version));

  const { createReview, createReviewState } = useCreateReview();

  const isNextClickDisabledForManageReviewerStep = () => {
    return levels.length === 0 || !levels.every((level: Level) => level.reviewers.length > 0) || !needByDate;
  };

  const reviewSubmissionSteps = [
    {
      labelText: 'Edited Policy View',
      renderStepComponent: () => <EditedPolicyViewerStep />,
      checkIfNextClickDisabled: () => false,
    },
    {
      labelText: 'Manager Reviewers',
      renderStepComponent: () => <ManageReviewersStep />,
      checkIfNextClickDisabled: isNextClickDisabledForManageReviewerStep,
    },
    {
      labelText: 'Review Policy Details',
      renderStepComponent: () => <ReviewPolicyReviewDetailsStep />,
      checkIfNextClickDisabled: isNextClickDisabledForManageReviewerStep,
      stepOnNextClick: () => {
        if (!getExecutionResponse) return;
        createReview({
          executionId: executionId as string,
          executionVersion: getExecutionResponse?.version || 1,
          approvalPlan: [
            ...levels.map((value, index) => {
              return {
                level: index + 1,
                title: value.levelName,
                users: value.reviewers.map((reviewer) => ({ alias: reviewer })),
              };
            }),
          ],
          needByDate: getEpochFromDateString(needByDate),
        });
      },
    },
  ];

  return (
    <ReviewSubmissionWorkflowContextProvider
      reviewSubmissionWorkflowInitial={{
        levels,
        setLevels,
        policyExecutionState: {
          policyExecutionError: isError ? error : null,
          policyExecutionResponse: getExecutionResponse,
          policyContentData: policyContentData,
          policyExecutionLoading: isLoading,
          executionId: executionId as string,
        },
        needByDate,
        setNeedByDate,
        createReview,
        createReviewState,
      }}
    >
      <Container>
        <WorkflowStepsContainer steps={reviewSubmissionSteps} onCancel={() => navigate('/')}>
          <ReviewSubmissionComplete />
        </WorkflowStepsContainer>
      </Container>
    </ReviewSubmissionWorkflowContextProvider>
  );
};

export default ReviewSubmissionWorkflow;
