import React, { useContext, useState } from 'react';
import { CellComponentType, SortDirection, Table, TableColumn, useSort } from '@amzn/stencil-react-components/table';
import { StencilContext } from '@amzn/stencil-react-components/context';
import { useNavigate } from 'react-router-dom';
import { compare, getBorderColor } from 'src/components/utils';
import { Pagination } from '@amzn/stencil-react-components/pagination';
import { CREATE_EXECUTION } from 'src/constants';
import {
  AvatarCell,
  ColoredText,
  DateText,
  DateTextBanner,
  ExecutionIdText,
  PolicyText,
  VersionComponent,
} from 'src/components/dashboard/shared';
import { ExecutionDetails } from 'src/models/api-models/getExecutions';

interface PolicyExecutionTableProps {
  data: ExecutionDetails[];
}

const PolicyExecutionTable = (props: PolicyExecutionTableProps) => {
  const { selectors } = useContext(StencilContext);
  const navigate = useNavigate();
  const pageSize = 4;
  const [currentPage, setCurrentPage] = useState(1);

  const columns: TableColumn<string, CellComponentType>[] = [
    { header: 'PolicyName', cellComponent: PolicyText, width: '25%' },
    { header: 'AssessmentId', cellComponent: ExecutionIdText, width: '15%' },
    { header: 'Version', cellComponent: VersionComponent },
    { header: 'Status', cellComponent: ColoredText },
    {
      header: 'Created at',
      sortId: 'createdAt',
      cellComponent: DateText,
    },
  ];

  const { activeSortId, sortDirection, onSort } = useSort({
    defaultSortDirection: SortDirection.Descending,
    defaultActiveSortId: 'createdAt',
  });

  const sortTable = (a: ExecutionDetails, b: ExecutionDetails) => {
    return compare(a, b, activeSortId);
  };

  const sortedData =
    sortDirection === SortDirection.Descending
      ? [...props.data].sort(sortTable).reverse()
      : [...props.data].sort(sortTable);

  const totalPages = Math.ceil(sortedData?.length / pageSize);
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentPageData = sortedData?.slice(startIndex, endIndex);

  const getRowAttributes = ({ data }: { data: any }) => ({
    style: {
      borderLeft: `solid ${selectors.color(getBorderColor(data?.status))}`,
    },
    disabled: false,
    actionLabel: `See details for ${data?.name}`,
    onClick: () => navigate(`${CREATE_EXECUTION}/${data?.executionId}/version/${data?.version}`),
  });

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  return (
    <>
      <Table
        aria-labelledby="policy-execution-table"
        maxHeight={'90vh'}
        getRowAttributes={getRowAttributes}
        columns={columns}
        data={currentPageData}
        hasStickyHeader={true}
        actionHeader={'View Details'}
        onSort={onSort}
        activeSortId={activeSortId}
        sortDirection={sortDirection}
      />
      <Pagination
        aria-labelledby="policy-execution-table-pagination"
        numberOfPages={totalPages}
        onPageSelect={handlePageChange}
        selectedPage={currentPage}
      />
    </>
  );
};

export default PolicyExecutionTable;
