import React, { useContext, useEffect, useState } from 'react';
import { CellComponentType, SortDirection, Table, TableColumn, useSort } from '@amzn/stencil-react-components/table';
import { StencilContext } from '@amzn/stencil-react-components/context';
import { useNavigate } from 'react-router-dom';
import { compare, getBorderColor } from 'src/components/utils';
import { Pagination } from '@amzn/stencil-react-components/pagination';
import { POLICY_REVIEW_WORKFLOW_PATH } from 'src/constants';
import { ActorRole, ReviewDetail } from 'src/models';
import {
  AvatarCell,
  ColoredText,
  DateText,
  DateTextBanner,
  ExecutionIdText,
  ExecutionVersionComponent,
  TitleText,
  VersionComponent,
} from './shared/TableComponents';

interface PolicyReviewTableProps {
  data: ReviewDetail[];
  actorRole: ActorRole;
}

const PolicyReviewTable = (props: PolicyReviewTableProps) => {
  const columns: TableColumn<string, CellComponentType>[] = [
    { header: 'Title', cellComponent: TitleText, width: '25%' },
    { header: 'ExecutionId', cellComponent: ExecutionIdText, width: '15%' },
    { header: 'Version', cellComponent: ExecutionVersionComponent },
    { header: 'Status', cellComponent: ColoredText },
    {
      header: 'Created at',
      cellComponent: DateText,
    },
    {
      header: 'Need by',
      sortId: 'needByDate',
      cellComponent: DateTextBanner,
    },
    ...(props.actorRole === 'APPROVER' ? [{ header: 'Submitted By', cellComponent: AvatarCell }] : []),
  ];

  const { selectors } = useContext(StencilContext);
  const navigate = useNavigate();
  const pageSize = 6;
  const [currentPage, setCurrentPage] = useState(1);

  const { activeSortId, sortDirection, onSort } = useSort({
    defaultSortDirection: SortDirection.Descending,
    defaultActiveSortId: 'submissionDate',
  });

  const sortTable = (a: ReviewDetail, b: ReviewDetail) => {
    return compare(a, b, activeSortId);
  };

  const sortedData =
    sortDirection === SortDirection.Descending
      ? [...props.data].sort(sortTable).reverse()
      : [...props.data].sort(sortTable);

  const totalPages = Math.ceil(sortedData?.length / pageSize);
  const startIndex = (currentPage - 1) * pageSize;
  const endIndex = startIndex + pageSize;
  const currentPageData = sortedData?.slice(startIndex, endIndex);

  const getRowAttributes = ({ data }: { data: any }) => ({
    style: {
      borderLeft: `solid ${selectors.color(getBorderColor(data?.status))}`,
    },
    disabled: false,
    actionLabel: `See details for ${data?.name}`,
    onClick: () => navigate(`${POLICY_REVIEW_WORKFLOW_PATH}/${data?.executionId}/version/${data?.executionVersion}`),
  });

  const handlePageChange = (newPage: number) => {
    setCurrentPage(newPage);
  };

  return (
    <>
      <Table
        aria-labelledby="policy-review-table"
        maxHeight={'90vh'}
        getRowAttributes={getRowAttributes}
        columns={columns}
        data={currentPageData}
        hasStickyHeader={true}
        actionHeader={'View Details'}
        onSort={onSort}
        activeSortId={activeSortId}
        sortDirection={sortDirection}
      />
      <Pagination
        aria-labelledby="policy-review-table-pagination"
        numberOfPages={totalPages}
        onPageSelect={handlePageChange}
        selectedPage={currentPage}
      />
    </>
  );
};

export default PolicyReviewTable;
