import React, { useCallback, useEffect, useState } from 'react';
import { LevelBasedReviewersCard } from 'src/components/shared';
import { ButtonCard } from '@amzn/stencil-react-components/card';
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import { H3, Label, Text } from '@amzn/stencil-react-components/text';
import { IconMinusCircleFill, IconPlus, IconPlusCircleFill } from '@amzn/stencil-react-components/icons';
import { Col, GridItem, Row } from '@amzn/stencil-react-components/layout';
import { ModalContent, WithModal } from '@amzn/stencil-react-components/modal';
import { Input } from '@amzn/stencil-react-components/form';
import { MessageBanner, MessageBannerType } from '@amzn/stencil-react-components/message-banner';
import { useReviewerManager } from 'src/components';
import { PopupDatePicker } from '@amzn/stencil-react-components/date-picker';
import { SearchField } from '@amzn/stencil-react-components/search';
import { useSearchEmployees } from 'src/hooks/useSearchEmployee';
import { AvatarSize, PersonalDetailsAvatar } from '@amzn/stencil-react-components/avatar';
import debounce from 'lodash.debounce';
import { SearchEmployeeRecord } from 'src/models';
import RequesterActionableAvatarCard from './RequesterActionableAvatarCard';

interface Level {
  levelName: string;
  reviewers: string[];
}

const ManageLevelBasedReviewersComponent = () => {
  const [levelName, setLevelName] = useState<string>('');
  const [reviewerAlias, setReviewerAlias] = useState<string>('');
  const [searchText, setSearchText] = useState<string>('');
  const [enableAddApprover, setEnableAddApprover] = useState(false);

  const {
    levels,
    addReviewerToLevel,
    removeLastLevel,
    addLevel,
    errorMessage,
    removeReviewerFromLevel,
    setErrorMessage,
    needByDate,
    setNeedByDate,
  } = useReviewerManager();

  const debouncedSearch = useCallback(
    debounce((value: string) => {
      setReviewerAlias(value);
    }, 500),
    [],
  );

  useEffect(() => {
    return () => {
      debouncedSearch.cancel(); // Cancel any pending debounced calls on unmount
    };
  }, [debouncedSearch]);

  const handleSearch = (value: string) => {
    setSearchText(value);
    debouncedSearch(value);
  };

  useEffect(() => {
    if (reviewerAlias && reviewerAlias.length >= 3) {
      searchEmployees(reviewerAlias);
    }
  }, [reviewerAlias]);

  const { data: { records: reviewerSuggestions = [] } = {}, isLoading, searchEmployees } = useSearchEmployees();

  const renderResult = (result: SearchEmployeeRecord) => (
    <Row gridGap={'S200'} justifyContent="space-between">
      <PersonalDetailsAvatar
        size={AvatarSize.Small}
        username={result.basicInfo.login.toLowerCase()}
        fullName={`${result.basicInfo.firstName} ${result.basicInfo.lastName}`}
        level={result.job.jobLevel}
        businessTitle={result.basicInfo.businessTitle}
      />
    </Row>
  );

  const customRenderer = ({ htmlFor, labelText }: { labelText: string; htmlFor?: string }) => (
    <Label htmlFor={htmlFor} fontWeight={'bold'}>
      {labelText} <span aria-hidden={true}>*</span>
    </Label>
  );

  const handleReviewerSearchModalClose = (level: Level) => {
    addReviewerToLevel(level.levelName, searchText);
    setReviewerAlias('');
    setSearchText('');
    setEnableAddApprover(false);
  };

  const isBeforeToday = (date: string): boolean => new Date(date) < new Date();

  const renderAddReviewerModal = ({ close }: { close: () => void }) => (
    <ModalContent
      titleText="Enter reviewer alias"
      buttons={[
        <Button onClick={close} key="add-reviewer-button" variant={ButtonVariant.Primary} disabled={!enableAddApprover}>
          Add Reviewer
        </Button>,
      ]}
    >
      <Col justifyContent={'top'} minHeight={'20rem'} minWidth={'30rem'}>
        <Label htmlFor="alias-search-field" id="alias-search-label">
          Search reviewer
        </Label>
        <SearchField
          id="alias-search-field"
          isLoading={isLoading}
          labelId="alias-search-label"
          listPositioningStrategy="absolute"
          onChange={handleSearch}
          query={searchText}
          listMaxHeight={'18rem'}
          resultToString={(result) => result.basicInfo.login}
          results={reviewerSuggestions?.filter((value) => !!value.basicInfo.login) as []}
          renderResult={renderResult}
          onResultClick={() => setEnableAddApprover(true)}
        />
      </Col>
    </ModalContent>
  );

  const renderAddLevelModal = ({ close }: { close: () => void }) => (
    <ModalContent
      titleText="Enter level name"
      buttons={[
        <Button onClick={close} key="create-level-button" variant={ButtonVariant.Primary} disabled={!levelName}>
          Create Level
        </Button>,
      ]}
    >
      <Input onChange={(e) => setLevelName(e.target.value)} />
    </ModalContent>
  );

  return (
    <Col gridGap={'S400'}>
      {!!errorMessage && (
        <Row width={'100%'}>
          <MessageBanner
            type={MessageBannerType.Error}
            autoDismissAfter={30000}
            isDismissible
            onDismissed={() => setErrorMessage(null)}
          >
            <Text>{errorMessage}</Text>
          </MessageBanner>
        </Row>
      )}
      <Row>
        <PopupDatePicker
          isDateDisabled={isBeforeToday}
          id="need-by-date-datepicker"
          labelText="Approval need by date"
          onChange={setNeedByDate}
          renderLabel={customRenderer}
          value={needByDate}
          inputProps={{ 'aria-required': true }}
        />
      </Row>
      <H3>Add Reviewers</H3>
      {levels.map((level: Level) => (
        <LevelBasedReviewersCard levelName={level.levelName} key={level.levelName}>
          {level.reviewers.map((alias) => (
            <RequesterActionableAvatarCard
              alias={alias}
              key={alias}
              levelName={level.levelName}
              onRemove={removeReviewerFromLevel}
            />
          ))}
          <GridItem lg={3} md={4} sm={4}>
            <WithModal renderModal={renderAddReviewerModal} onClose={() => handleReviewerSearchModalClose(level)}>
              {({ open }) => (
                <ButtonCard
                  width={'100%'}
                  minHeight={'167px'}
                  height={'100%'}
                  justifyContent={'center'}
                  alignItems="center"
                  onClick={open}
                >
                  <Button icon={<IconPlus />} />
                </ButtonCard>
              )}
            </WithModal>
          </GridItem>
        </LevelBasedReviewersCard>
      ))}
      <Row gridGap={'S300'} justifyContent={'center'}>
        <WithModal renderModal={renderAddLevelModal} onClose={() => addLevel(levelName)}>
          {({ open }) => (
            <>
              <Button icon={<IconPlusCircleFill />} onClick={open}>
                Add Level
              </Button>
              <span hidden id="sroDialog">
                has pop-up dialog
              </span>
            </>
          )}
        </WithModal>
        {!levels ||
          (levels.length !== 0 && (
            <Button isDestructive icon={<IconMinusCircleFill />} onClick={removeLastLevel}>
              Remove Level
            </Button>
          ))}
      </Row>
    </Col>
  );
};

export default ManageLevelBasedReviewersComponent;
