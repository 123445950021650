import React from 'react';
import { Card } from '@amzn/stencil-react-components/card';
import { H2, Text } from '@amzn/stencil-react-components/text';
import { IconCrossInsideCircle, IconSize, IconTick } from '@amzn/stencil-react-components/icons';
import { Col, Flex, Row } from '@amzn/stencil-react-components/layout';
import { useReviewSubmissionWorkflowContext } from 'src/components';
import { Spinner, SpinnerSize } from '@amzn/stencil-react-components/spinner';
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import { useNavigate } from 'react-router-dom';
import { POLICY_REVIEW_WORKFLOW_PATH } from 'src/constants';
import { Link } from '@amzn/stencil-react-components/link';

const DEFAULT_EXECUTION_VERSION = 1;

const ReviewSubmissionComplete = () => {
  const { createReviewState, policyExecutionState } = useReviewSubmissionWorkflowContext();
  const navigate = useNavigate();
  return (
    <>
      {createReviewState.loading ? (
        <Spinner showText size={SpinnerSize.Large} loadingText="Creating your Review" />
      ) : (
        <Card justifyContent={'center'}>
          <Col gridGap={'S300'} alignItems={'center'} padding={'S300'}>
            {createReviewState.error && !createReviewState.data ? (
              <>
                <H2>Review Submission Failed</H2>
                <Text>
                  <IconCrossInsideCircle size={IconSize.Large} color={'red70'} />
                </Text>
                <Text>Review Submission Failed with error : {`${createReviewState.error}`}</Text>
              </>
            ) : (
              <>
                <H2>Review Submission Completed</H2>
                <Text>
                  <IconTick size={IconSize.Large} color={'green70'} />
                </Text>
                <Text>
                  Review Submission Completed with review id :{' '}
                  <Link
                    href={`${POLICY_REVIEW_WORKFLOW_PATH}/${policyExecutionState.executionId}/version/${
                      policyExecutionState?.policyExecutionResponse?.version ?? DEFAULT_EXECUTION_VERSION
                    }`}
                  >
                    {`${createReviewState?.data?.reviewId}`}{' '}
                  </Link>{' '}
                </Text>
              </>
            )}
            <Col alignItems={'center'} width={'100%'} gridGap="S300">
              <Button
                onClick={() => {
                  navigate('/');
                }} // TODO: Change behaviour if needed.
                variant={ButtonVariant.Primary}
              >
                Close
              </Button>
            </Col>
          </Col>
        </Card>
      )}
    </>
  );
};

export default ReviewSubmissionComplete;
