import React from 'react';
import { Col, View } from '@amzn/stencil-react-components/layout';
import {
  BaseAvatarCard,
  LevelBasedReviewersCard,
  PolicyDiffViewerComponent,
  useReviewSubmissionWorkflowContext,
} from 'src/components';
import { Card } from '@amzn/stencil-react-components/card';
import { H3 } from '@amzn/stencil-react-components/text';
import { PopupDatePicker } from '@amzn/stencil-react-components/date-picker';

const ReviewPolicyReviewDetailsStep = () => {
  const { levels, policyExecutionState, needByDate } = useReviewSubmissionWorkflowContext();

  return (
    <Col gridGap={'S400'}>
      {policyExecutionState.policyContentData
        .filter((contentData) => contentData.editedContent && contentData.originalContent)
        .map((contentData) => (
          <PolicyDiffViewerComponent
            key={contentData.policyId}
            newHtmlData={contentData.editedContent as string}
            oldHtmlData={contentData.originalContent as string}
          />
        ))}
      <Col gridGap={'S400'}>
        <H3>Approval need by date </H3>
        <PopupDatePicker
          disabled={true}
          id="need-by-date-datepicker"
          value={needByDate}
          inputProps={{ 'aria-required': true }}
        />
      </Col>
      <Card width={'100%'}>
        <Col gridGap={'S400'} width={'100%'}>
          <H3>Reviewer Chain</H3>
          {levels?.map((level, index) => {
            return (
              <View width={'100%'} padding={'S200'} key={level.levelName}>
                <LevelBasedReviewersCard levelName={level.levelName} isCardView={false}>
                  {level.reviewers?.map((reviewer: string, index: number) => {
                    return (
                      <BaseAvatarCard
                        gridItemProps={{ lg: 2, md: 2, sm: 4 }}
                        userInfo={{ alias: reviewer }}
                        key={reviewer}
                      />
                    );
                  })}
                </LevelBasedReviewersCard>
              </View>
            );
          })}
        </Col>
      </Card>
    </Col>
  );
};

export default ReviewPolicyReviewDetailsStep;
