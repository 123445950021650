import React from 'react';
import { Button, ButtonVariant } from '@amzn/stencil-react-components/button';
import { FlyoutContent, WithFlyout } from '@amzn/stencil-react-components/flyout';
import { IconNotification } from '@amzn/stencil-react-components/icons';
import { Text } from '@amzn/stencil-react-components/text';
import { PageHeaderButton } from '@amzn/stencil-react-components/page';

const NotificationComponent = () => {
  const renderFlyout = ({ close }: { close: () => void }) => (
    <FlyoutContent
      titleText="Notifications"
      onCloseButtonClick={close}
      buttons={[
        <Button key="done" onClick={close} variant={ButtonVariant.Primary}>
          Done
        </Button>,
      ]}
    >
      <Text>No notifications</Text>
    </FlyoutContent>
  );
  return (
    <WithFlyout key="notifications" renderFlyout={renderFlyout}>
      {({ open }) => (
        <>
          <PageHeaderButton onClick={open} icon={<IconNotification aria-label="notifications" />} />
          <span hidden id="sroDialog">
            has pop-up dialog
          </span>
        </>
      )}
    </WithFlyout>
  );
};

export default NotificationComponent;
