import React, { createContext, useContext } from 'react';
import { ActorRole, Level, PerformApproverActionRequest } from 'src/models';
import { PerformApproverActionState, PolicyContentData } from 'src/hooks';

export const PolicyReviewWorkflowContext = createContext<PolicyReviewWorkflowContextType | undefined>(undefined);

interface PolicyReviewWorkflowContextType {
  levels: Level[];
  actorRole: ActorRole;
  executionId: string;
  executionVersion: number;
  policyContentDataItems: PolicyContentData[];
  reviewerAction: (action: PerformApproverActionRequest) => void;
  reviewerActionState: PerformApproverActionState;
  getReview: () => void;
}

export const PolicyReviewWorkflowContextProvider: React.FC<{
  policyReviewWorkflowInitial: PolicyReviewWorkflowContextType;
  children: React.ReactNode;
}> = ({ policyReviewWorkflowInitial, children }) => {
  return (
    <PolicyReviewWorkflowContext.Provider
      value={{
        ...policyReviewWorkflowInitial,
      }}
    >
      {children}
    </PolicyReviewWorkflowContext.Provider>
  );
};

export const usePolicyReviewWorkflowContext = (): PolicyReviewWorkflowContextType => {
  const context = useContext(PolicyReviewWorkflowContext);
  if (!context) {
    throw new Error('usePolicyReviewWorkflowContext must be used within a PolicyReviewWorkflowContextProvider');
  }
  return context;
};
