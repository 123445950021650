import React, { useEffect, useState } from 'react';
import { DynamicSpinnerComponent, useReviewSubmissionWorkflowContext } from 'src/components';
import { Col, Flex, Row, Spacer } from '@amzn/stencil-react-components/layout';
import { Spinner, SpinnerSize } from '@amzn/stencil-react-components/spinner';
import { Text } from '@amzn/stencil-react-components/text';
import PolicyEditorComponent from 'src/components/shared/policy-editor/PolicyEditorComponent';
import { PolicyContentData } from 'src/hooks';
import { Button, ButtonIconPosition, ButtonVariant } from '@amzn/stencil-react-components/button';
import { deserializeFromMarkdown, HtmlRenderer } from '@amzn/stencil-react-components/rte';
import { useExecuteComparison } from 'src/hooks/useExecuteComparison';
import { ExecuteComparisonRequest } from 'src/models/api-models/executeComparison';
import { IconDocument } from '@amzn/stencil-react-components/icons';
import { UploadFileContentMetadata, useUploadFilesOnPreSignedURL } from 'src/hooks/useUploadFilesOnPresignedURL';
import { SERVICE } from 'src/paths';

const EditedPolicyViewerStep = () => {
  const { policyExecutionState } = useReviewSubmissionWorkflowContext();
  const [policiesContentState, setPoliciesContentState] = useState<PolicyContentData[]>([]);
  const { executeComparisonState, executeComparison } = useExecuteComparison();
  const [policyComparisonResult, setPolicyComparisonResult] = useState<string | undefined>(undefined);
  const { uploadState, uploadFilesOnPreSignedURL } = useUploadFilesOnPreSignedURL();
  useEffect(() => {
    if (policyExecutionState.policyContentData) {
      setPoliciesContentState(policyExecutionState.policyContentData);
    }
    setPolicyComparisonResult(policyExecutionState?.policyExecutionResponse?.policyComparisonData?.result);
  }, [policyExecutionState]);

  useEffect(() => {
    if (executeComparisonState?.data?.comparisonResults?.result) {
      setPolicyComparisonResult(executeComparisonState?.data?.comparisonResults?.result);
    }
  }, [executeComparisonState]);

  const isLoading = policyExecutionState.policyExecutionLoading;
  const hasError = policyExecutionState.policyExecutionError;
  const policies = policyExecutionState?.policyExecutionResponse?.policies;
  const handlePolicyContentUpdate = (updatedContent: string, index: number) => {
    setPoliciesContentState((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = {
        ...newStates[index],
        editedContent: updatedContent,
      };
      return newStates;
    });
  };

  const handlePoliciesSave = () => {
    const fileContentWithS3PathList = policiesContentState
      .filter((policyContent) => policyContent.editedFilePath)
      .map(
        (policyContent) =>
          ({
            service: SERVICE.PES,
            fileContent: policyContent.editedContent || '',
            s3Path: policyContent.editedFilePath as string,
          } as UploadFileContentMetadata),
      );
    uploadFilesOnPreSignedURL(fileContentWithS3PathList);
  };

  const runAuditOnPolicies = () => {
    if (
      policyExecutionState?.policyExecutionResponse?.executionId &&
      policyExecutionState?.policyExecutionResponse?.version
    ) {
      executeComparison({
        executionId: policyExecutionState.policyExecutionResponse.executionId,
        executionVersion: policyExecutionState.policyExecutionResponse.version,
      } as ExecuteComparisonRequest);
    }
  };

  const renderComparisonResults = () => {
    if (executeComparisonState.loading) {
      return (
        <Row height={'80vh'}>
          <DynamicSpinnerComponent />
        </Row>
      );
    }
    if (executeComparisonState.error) {
      //TODO: Handle showing error
      return <Text>An error occurred while fetching policy comparison results</Text>;
    }
    return (
      <Col>
        <Row justifyContent={'right'} margin={{ bottom: '0.5rem' }}>
          {renderSaveButton()}
          <Spacer width={'0.4rem'}></Spacer>
          <Button
            variant={ButtonVariant.Primary}
            onClick={runAuditOnPolicies}
            icon={<IconDocument aria-hidden={true} />}
            iconPosition={ButtonIconPosition.Trailing}
          >
            Run audit
          </Button>
        </Row>
        <>
          {policyComparisonResult ? (
            <HtmlRenderer html={deserializeFromMarkdown(policyComparisonResult as string) as string} />
          ) : null}
        </>
      </Col>
    );
  };

  if (isLoading) {
    return <Spinner size={SpinnerSize.Medium} />;
  }

  if (hasError) {
    //TODO: Handle error using a component
    return <Text>An error occurred while fetching policy details</Text>;
  }

  if (!policies) {
    return <Text>No policies are present.</Text>; //TODO: Handle error using a component
  }

  const renderEditablePolicies = () => {
    //TODO: Handle by showing an expander
    return policiesContentState.map(
      (policyContentData, index) =>
        policyContentData.editedFilePath && (
          <PolicyEditorComponent
            key={policyContentData.policyId}
            isReadOnlyMode={false}
            executionResult={{
              updatedPolicy: policyContentData.editedContent || policyContentData.originalContent || '',
              currentPolicy: policyContentData.originalContent || '',
            }}
            policyContentUpdateCallback={(updatedContent: string) => handlePolicyContentUpdate(updatedContent, index)}
          />
        ),
    );
  };

  const renderSaveButton = () => {
    if (uploadState.loading) {
      return <Spinner size={SpinnerSize.Small} />;
    }

    if (uploadState.error) {
      return <Text>An error occurred while saving policies</Text>;
    }

    return <Button onClick={handlePoliciesSave}>Save policies</Button>;
  };

  return (
    <Col>
      <Flex flexDirection={'row'}>
        <Col width={'60%'}>{renderEditablePolicies()}</Col>
        <Col width={'40%'}>{renderComparisonResults()}</Col>
      </Flex>
    </Col>
  );
};

export default EditedPolicyViewerStep;
