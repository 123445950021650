import React from 'react';
import { ButtonCard, Card, CardStatus, LinkCard } from '@amzn/stencil-react-components/card';
import { Col, Row } from '@amzn/stencil-react-components/layout';
import { Text } from '@amzn/stencil-react-components/text';
import { Spinner } from '@amzn/stencil-react-components/spinner';
import { styledWithTheme } from '@amzn/stencil-react-components/context';

interface DashboardCardProps {
  title: string;
  count: number;
  isActive: boolean;
  onClick: () => void;
  status: CardStatus;
  isLoading?: boolean;
}

const DashboardCard: React.FC<DashboardCardProps> = ({ title, count, isActive, onClick, status, isLoading }) => {
  const StyledButtonCard = styledWithTheme(ButtonCard)(({ theme }) => ({
    height: '100%',
    width: '100%',
    borderColor: isActive ? theme.selectors.color('primary70') : undefined,
    borderWidth: isActive ? '0.2rem' : '0rem',
    borderStyle: isActive ? 'solid' : undefined,
  }));

  return (
    <StyledButtonCard onClick={onClick} status={status}>
      <Col width="100%" gridGap="S200">
        <Row alignItems="center" justifyContent="center">
          <Text fontSize="T400" fontWeight="medium">
            {title}
          </Text>
        </Row>
        <Row alignItems="center" justifyContent="center" padding="S400">
          {isLoading ? (
            <Spinner />
          ) : (
            <Text fontSize="T600" textAlign="center">
              {count !== null ? count : '0'}
            </Text>
          )}
        </Row>
      </Col>
    </StyledButtonCard>
  );
};

export default DashboardCard;
