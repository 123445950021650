import React from 'react';
import { Spacer } from '@amzn/stencil-react-components/layout';
import { PageHeader, PageHeaderLink, PageHeaderList } from '@amzn/stencil-react-components/page';
import { H2, Text } from '@amzn/stencil-react-components/text';
import { Avatar, AvatarSize } from '@amzn/stencil-react-components/avatar';
import { Button, ButtonColorScheme, ButtonVariant } from '@amzn/stencil-react-components/button';
import { IconFilter, IconQuestionCircle } from '@amzn/stencil-react-components/icons';
import { HOME_PAGE_ROUTE } from 'src/constants';
import SearchBoxComponent from 'src/components/searchbox/SearchBoxComponent';
import NotificationComponent from 'src/components/notifications/NotificationsComponent';
import AMAZON_LOGO from '../../../public/assests/images/AMAZON_LOGO.svg';

interface NavigationBarComponentProps {
  userName: string;
}

const NavigationBarComponent = (props: NavigationBarComponentProps) => {
  return (
    <PageHeader isFullWidth={true} isFixed={true}>
      <PageHeaderLink href={HOME_PAGE_ROUTE}>
        <img src={AMAZON_LOGO} alt="Amazon" style={{ height: '2rem' }} />
        <H2 fontWeight="regular" color="white">
          Policy Advisor
        </H2>
      </PageHeaderLink>
      <Spacer flex={1.4} />
      <PageHeaderList
        items={[
          <SearchBoxComponent key="search" />,
          <Button
            key="filter"
            color="white"
            variant={ButtonVariant.Tertiary}
            colorScheme={ButtonColorScheme.DarkBackground}
            aria-describedby="sroDialog"
            onClick={() => {}}
            icon={<IconFilter color="white" title="Advanced Filters" />}
          />,
        ]}
      />
      <Spacer flex={1} />
      <PageHeaderList
        items={[
          <PageHeaderLink key="help" href="#">
            <IconQuestionCircle aria-label="help" />
          </PageHeaderLink>,
          <PageHeaderLink key="contact" href="#">
            Contact Us
          </PageHeaderLink>,
          <NotificationComponent key="notifications" />,
        ]}
      />
      <Spacer flex={0.3} />
      <Avatar username={props.userName} showUsername={false} size={AvatarSize.Small} />
    </PageHeader>
  );
};

export default NavigationBarComponent;
