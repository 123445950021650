import React, { useEffect, useState } from 'react';
import { Col } from '@amzn/stencil-react-components/layout';
import { Spinner, SpinnerSize } from '@amzn/stencil-react-components/spinner';

export const DynamicSpinnerComponent = () => {
  const loadingMessages = [
    'Loading...',
    'Evaluating your edited policy...',
    'Generating intelligent response...',
    'We are almost there...',
  ];

  const [currentMessage, setCurrentMessage] = useState(loadingMessages[0]);
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => {
        const newIndex = prevIndex + 1 >= loadingMessages.length ? loadingMessages.length - 1 : prevIndex + 1;
        setCurrentMessage(loadingMessages[newIndex]);
        return newIndex;
      });
    }, 5000);

    return () => clearInterval(interval);
  }, [loadingMessages]);

  return (
    <Col justifyContent={'center'} width={'100%'}>
      <Spinner showText size={SpinnerSize.Medium} loadingText={currentMessage} fontSize={'T300'} />
    </Col>
  );
};
