import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Container } from '@amzn/stencil-react-components/layout';
import { useParams } from 'react-router';
import { DetailsPage } from 'src/components';
import { ProgressTrackerStepStatus } from '@amzn/stencil-react-components/progress-tracker';
import { Spinner, SpinnerSize } from '@amzn/stencil-react-components/spinner';
import WorkflowStepsContainer from '../shared/workflow-steps-container/WorkflowStepsContainer';
import { Step } from '../shared';
import { useCreateExecutionWorkflow } from './hooks';
import { CreateExecutionWorkflowContextProvider } from './context/CreateExecutionWorkflowContext';
import { CreateExecutionComplete } from './steps/CreateExecutionComplete';
import { CreateExecutionPreviewStep, SelectPoliciesStep, SelectSpecificationsStep } from './steps';

const STEP_1_LABEL = 'Select Policies';
const STEP_2_LABEL = 'Select Specifications';
const STEP_3_LABEL = 'Preview Assessment Request';
const STEP_4_LABEL = 'Assessment Results';

export const CreateExecutionWorkflow = () => {
  const navigate = useNavigate();
  const { executionId, version: executionVersion } = useParams<{ executionId: string; version: string }>();
  const {
    namespaceOptions,
    selectedNamespaceId,
    createExecutionState,
    createExecution,
    getPoliciesMetadataState,
    handleNamespaceIdSelection,
    isNextDisabledForSelectPoliciesStep,
    selectedPolicies,
    updateSelectedPolicies,
    selectPoliciesValidationError,
    additionalInstructions,
    handleAdditionalInstructionsUpdate,
    selectedParameters,
    handleParameterChange,
    handleCreateExecutionOnSubmit,
    toggleMyTimeCheckbox,
    compareWithMyTimeCheckbox,
    handleCompareWithMyTimeCheckboxUpdate,
    submittingExecution,
    isModalOpen,
    openModal,
    closeModal,
    onModalSubmit,
    policyExecutionResponse,
    isWorkflowLoading,
  } = useCreateExecutionWorkflow({ executionId, version: Number(executionVersion) });

  const baseSteps: Step[] = [
    {
      labelText: STEP_1_LABEL,
      renderStepComponent: () => <SelectPoliciesStep />,
      checkIfNextClickDisabled: isNextDisabledForSelectPoliciesStep,
      status: policyExecutionResponse ? ProgressTrackerStepStatus.Disabled : undefined,
    },
    {
      labelText: STEP_2_LABEL,
      renderStepComponent: () => <SelectSpecificationsStep />,
      status: policyExecutionResponse ? ProgressTrackerStepStatus.Disabled : undefined,
    },
    {
      labelText: STEP_3_LABEL,
      renderStepComponent: () => <CreateExecutionPreviewStep />,
      stepOnNextClick: () => handleCreateExecutionOnSubmit(),
      showActions: !(policyExecutionResponse || createExecutionState.loading || submittingExecution),
      status: policyExecutionResponse ? ProgressTrackerStepStatus.Complete : undefined,
    },
    {
      labelText: STEP_4_LABEL,
      renderStepComponent: () => <DetailsPage />,
      stepOnNextClick: () => null,
      showActions: !(policyExecutionResponse || createExecutionState.loading || submittingExecution),
      status: policyExecutionResponse ? ProgressTrackerStepStatus.Current : undefined,
    },
  ];

  const getSteps = (): Step[] => {
    return baseSteps;
  };

  if (isWorkflowLoading) {
    return <Spinner size={SpinnerSize.Large}></Spinner>;
  }
  return (
    <CreateExecutionWorkflowContextProvider
      createExecutionWorkflowInitial={{
        namespaceOptions: namespaceOptions,
        selectedNamespaceId: selectedNamespaceId,
        handleNamespaceIdSelection: handleNamespaceIdSelection,
        createExecutionState: createExecutionState,
        createExecution: createExecution,
        getPoliciesMetadataState: getPoliciesMetadataState,
        selectedPolicies: selectedPolicies,
        updateSelectedPolicies: updateSelectedPolicies,
        selectPoliciesValidationError: selectPoliciesValidationError,
        additionalInstructions: additionalInstructions,
        handleAdditionalInstructionsUpdate: handleAdditionalInstructionsUpdate,
        selectedParameters: selectedParameters,
        handleParameterChange: handleParameterChange,
        toggleMyTimeCheckbox: toggleMyTimeCheckbox,
        compareWithMyTimeCheckbox: compareWithMyTimeCheckbox,
        handleCompareWithMyTimeCheckboxUpdate: handleCompareWithMyTimeCheckboxUpdate,
        submittingExecution: submittingExecution,
        isModalOpen: isModalOpen,
        openModal: openModal,
        closeModal: closeModal,
        onModalSubmit: onModalSubmit,
      }}
    >
      <Container>
        <WorkflowStepsContainer steps={getSteps()} onCancel={() => navigate('/')}>
          <CreateExecutionComplete />
        </WorkflowStepsContainer>
      </Container>
    </CreateExecutionWorkflowContextProvider>
  );
};
