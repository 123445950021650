import { useMutation } from '@tanstack/react-query';
import { HttpError, postData } from 'src/clients';
import { PostApiParams } from 'src/types';
import { useState } from 'react';
import { EXECUTE_COMPARISON_API } from 'src/paths';
import { ExecuteComparisonRequest, ExecuteComparisonResponse } from "src/models/api-models/executeComparison";

export const useExecuteComparison = () => {
    const {
        isLoading: loading,
        error,
        data: responseData,
        mutate,
    } = useMutation<unknown, HttpError, PostApiParams<unknown>>(postData());
    const [retryParams, setRetryParams] = useState<ExecuteComparisonRequest | undefined>(undefined);
    const path = EXECUTE_COMPARISON_API;
    const executeComparison = (executeComparisonRequest: ExecuteComparisonRequest) => {
        setRetryParams(undefined);
        mutate(
            {
                path,
                method: 'POST',
                body: executeComparisonRequest,
            },
            {
                onError: () => {
                    setRetryParams({ ...executeComparisonRequest });
                },
            },
        );
    };

    const retry = () => {
        if (!retryParams || loading) return;
        executeComparison(retryParams);
    };

    return {
        executeComparisonState: {
            data: responseData as ExecuteComparisonResponse,
            loading,
            error: retryParams ? error : null,
            retry,
        },
        executeComparison,
    };
};
