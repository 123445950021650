import { HttpError, postData } from 'src/clients';
import { useMutation } from '@tanstack/react-query';
import { PostApiParams } from 'src/types';
import { useState } from 'react';
import { CreateReviewRequest, CreateReviewResponse } from 'src/models';
import { CREATE_REVIEW_API } from 'src/paths';

export const useCreateReview = () => {
  const {
    isLoading: loading,
    error,
    data: responseData,
    mutate,
  } = useMutation<unknown, HttpError, PostApiParams<unknown>>(postData());
  const [retryParams, setRetryParams] = useState<CreateReviewRequest | undefined>(undefined);
  const path = CREATE_REVIEW_API();
  const createReview = (createReviewRequest: CreateReviewRequest) => {
    setRetryParams(undefined);
    mutate(
      {
        path,
        method: 'POST',
        body: createReviewRequest,
      },
      {
        onError: (err) => {
          setRetryParams({ ...createReviewRequest });
        },
      },
    );
  };

  const retry = () => {
    if (!retryParams || loading) return;
    createReview(retryParams);
  };

  return {
    createReviewState: {
      data: responseData as CreateReviewResponse,
      loading,
      error: retryParams ? error : null,
      retry,
    },
    createReview,
  };
};
