import React, { ReactNode, useEffect, useState } from 'react';
import {
  AuthUser,
  fetchAuthSession,
  fetchUserAttributes,
  FetchUserAttributesOutput,
  getCurrentUser,
  signInWithRedirect,
} from 'aws-amplify/auth';
import { POST_AUTH_REDIRECT_STORAGE_ITEM } from 'src/constants/SessionConstants';
import { Spinner, SpinnerSize } from '@amzn/stencil-react-components/spinner';
import { AuthenticatedUser } from 'src/model/AuthenticatedUser';
import { storeAuthorizationTokenInStorage, storeIAMCredentialsInStorage } from 'src/utils';
import { getUserPoolIdp } from './AmplifyConfig';

/**
 * A function to store the pathname before cognito redirect in session storage.
 */
const setPostAuthRedirect = () => {
  const pathname = window.location.pathname;
  window.sessionStorage.setItem(POST_AUTH_REDIRECT_STORAGE_ITEM, pathname);
};

/**
 * A function to map cognito user attributes to authenticated user model.
 * @param input fetch user attributes object.
 * @returns Authenticated user model.
 */
function mapToAuthenticatedUser(input: FetchUserAttributesOutput): AuthenticatedUser {
  return {
    userName: input.preferred_username || '',
    email: input.email || '',
  };
}

const DEFAULT_AUTHENTICATION_INFO = {
  currentUser: null,
  authenticatedUser: null,
};

interface AuthenticationInfo {
  currentUser: AuthUser | null;
  authenticatedUser: AuthenticatedUser | null;
}

const AuthContext = React.createContext<AuthenticationInfo>(DEFAULT_AUTHENTICATION_INFO);

/**
 * Authentication controller which sets/controls the authentication using federate idp.
 * @param props react node children object.
 * @returns user context.
 */
const AuthenticationContext = (props: { children?: ReactNode }) => {
  // automatically sign-in
  // Ref: https://docs.amplify.aws/gen1/react/build-a-backend/auth/add-social-provider/#set-up-your-frontend
  const [currentUser, setCurrentUser] = useState<AuthUser | null>(null);
  const [authenticatedUser, setAuthenticatedUser] = useState<AuthenticatedUser | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    initializeUserSession();
  }, []);

  async function initializeUserSession() {
    try {
      const currentUser = await getCurrentUser();
      setCurrentUser(currentUser);
      const authSession = await fetchAuthSession();
      storeAuthorizationTokenInStorage(authSession.tokens?.idToken?.toString());
      storeIAMCredentialsInStorage(authSession?.credentials);
      const authenticatedUser: FetchUserAttributesOutput = await fetchUserAttributes();
      setAuthenticatedUser(mapToAuthenticatedUser(authenticatedUser));
    } catch (error) {
      console.error(error);
      setCurrentUser(null);
      setAuthenticatedUser(null);
    } finally {
      setLoading(false);
    }
  }

  // When loading true, show spinner for logging in.
  if (loading) {
    return <Spinner size={SpinnerSize.Large}></Spinner>;
  }

  // When loading false and current user not present sing in with redirect and return nothing.
  if (!currentUser) {
    setPostAuthRedirect();
    signInWithRedirect({
      provider: {
        custom: getUserPoolIdp(window.location.origin),
      },
    });
    // TODO: Add a new page to display the error message upon failure in authentication.
    return <></>;
  }

  // Set auth context for children nodes to consume in application.
  return <AuthContext.Provider value={{ currentUser, authenticatedUser }}>{props.children}</AuthContext.Provider>;
};

/**
 * A hook which exposes the auth context to users.
 * @returns user context.
 */
const useAuthentication = () => React.useContext(AuthContext);

export { AuthenticationContext, useAuthentication };
