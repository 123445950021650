const environmentMap: Record<string, any> = {
  'http://localhost': {
    name: 'development',
    userPoolId: 'us-west-2_vErojIinV',
    identityPoolId: 'us-west-2:224e0b9c-4ed0-42ea-b3f8-be85bc1e7d0b',
    userPoolClientId: '6guajr553146u080f8f63u2pv6',
    domain: 'policy-advisor-beta.auth.us-west-2.amazoncognito.com',
    userPoolAuthScope: ['openid', 'aws.cognito.signin.user.admin', 'email', 'profile'],
    userPoolAuthResponseType: 'code',
    userPoolIdp: 'AmazonFederate-Midway',
  },
  'https://alpha.policy-advisor.pxt.amazon.dev': {
    name: 'alpha',
    userPoolId: 'us-west-2_vErojIinV',
    identityPoolId: 'us-west-2:224e0b9c-4ed0-42ea-b3f8-be85bc1e7d0b',
    userPoolClientId: '6guajr553146u080f8f63u2pv6',
    domain: 'policy-advisor-beta.auth.us-west-2.amazoncognito.com',
    userPoolAuthScope: ['openid', 'aws.cognito.signin.user.admin', 'email', 'profile'],
    userPoolAuthResponseType: 'code',
    userPoolIdp: 'AmazonFederate-Midway',
  },
};

export const getEnvironment = (browserLocation: string) => {
  return environmentMap[browserLocation] || environmentMap['http://localhost'];
};

export const getUserPoolIdp = (browserLocation: string): string => {
  return environmentMap[browserLocation]?.['userPoolIdp'] || environmentMap['http://localhost']['userPoolIdp'];
};

export const environmentConfiguration = getEnvironment(window.location.origin);
