export enum Stage {
  TEST = 'test',
  BETA = 'beta',
  GAMMA = 'gamma',
  PROD = 'prod',
}

/**
 * Katal manifest templating will replace beta with the correct stage value
 * during build time. Bundle for the appropriate stage will have the correct
 * stage string value used for fetching the url
 * https://katal.corp.amazon.com/#/developer/manifest/templatingcdk
 */
export const useCurrentStage = (): Stage => {
  return 'beta'.toLowerCase() as Stage;
};
