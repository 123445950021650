import React from 'react';
import { TabBar, TabPanel, TabSwitcher, useTabs } from '@amzn/stencil-react-components/tabs';
import { Row, View } from '@amzn/stencil-react-components/layout';
import { PolicyDiffViewerComponent } from 'src/components/shared';
import { usePolicyReviewWorkflowContext } from 'src/components';
import ReviewerActionComponent from './ReviewerActionComponent';

const PolicyReviewStep = () => {
  const tabs: string[] = ['Policy Diff', 'Approvers'];
  const { policyContentDataItems } = usePolicyReviewWorkflowContext();

  const { tabBarProps, tabSwitcherProps } = useTabs({ tabs });
  return (
    <>
      <Row width={'100%'}>
        <TabBar {...tabBarProps} />
      </Row>
      <View width={'100%'} margin={{ top: 'S500' }}>
        <TabSwitcher {...tabSwitcherProps} shouldUnmountHiddenPanels={true}>
          <TabPanel value="Policy Diff">
            {policyContentDataItems
              .filter((policyContentData) => policyContentData.editedContent)
              .map((policyContentData) => (
                <PolicyDiffViewerComponent
                  key={policyContentData.policyId}
                  newHtmlData={policyContentData.editedContent || ''}
                  oldHtmlData={policyContentData.originalContent || ''}
                />
              ))}
          </TabPanel>
          <TabPanel value="Approvers">
            <ReviewerActionComponent />
          </TabPanel>
        </TabSwitcher>
      </View>
    </>
  );
};

export default PolicyReviewStep;
