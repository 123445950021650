import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getData } from 'src/clients';
import { PolicyExecutionResponse } from 'src/models';
import { GetExecutionsRequest, GetExecutionsResponse } from 'src/models/api-models/getExecutions';
import { GET_EXECUTIONS_API } from 'src/paths';
export const useGetExecutions = ({
  executionStatus,
}: { executionStatus?: string } = {}): UseQueryResult<GetExecutionsResponse> => {
  const path = executionStatus ? GET_EXECUTIONS_API(executionStatus) : GET_EXECUTIONS_API();

  return useQuery<unknown, unknown, GetExecutionsResponse>(
    ['executionStatus', executionStatus],
    () =>
      getData<GetExecutionsResponse>({
        path,
      }),
    {
      enabled: true, // Always enabled since executionStatus is optional
      staleTime: 1000 * 60,
    },
  );
};
