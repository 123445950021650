export enum ReviewApproverAction {
  APPROVE = 'APPROVE',
  APPROVE_WITH_CONDITION = 'APPROVE_WITH_CONDITION',
  REJECT = 'REJECT',
  INVALIDATE_APPROVAL = 'INVALIDATE_APPROVAL',
}

export interface PerformApproverActionRequest {
  executionId: string;
  executionVersion: number;
  action: ReviewApproverAction;
  comment?: string;
}

export interface PerformApproverActionResponse {
  reviewId: string;
}
