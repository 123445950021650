import React from 'react';
import { Col, GridItem, GridItemProps, Hr, Row } from '@amzn/stencil-react-components/layout';
import { Card } from '@amzn/stencil-react-components/card';
import { H4 } from '@amzn/stencil-react-components/text';
import { Avatar, AvatarText } from '@amzn/stencil-react-components/avatar';
import { useSearchEmployees } from 'src/hooks/useSearchEmployee';

interface BaseAvatarCardProps {
  userInfo: {
    alias: string;
    // TODO: commenting these hard-coded fields, to be enabled once data is fetched from PAPI
    // fullName: string;
    // role: string;
  };
  children?: React.ReactNode;
  gridItemProps?: GridItemProps;
}

const BaseAvatarCard: React.FC<BaseAvatarCardProps> = ({
  userInfo,
  children,
  gridItemProps = { lg: 3, md: 2, sm: 4 },
}) => {
  return (
    <GridItem {...gridItemProps}>
      <Card isElevated justifyContent={'center'} width={'100%'}>
        <Col gridGap={'S200'} alignItems={'center'} justifyContent={'center'}>
          <Col>
            <Row gridGap={'S300'}>
              <Avatar username={userInfo.alias}></Avatar>
            </Row>
          </Col>
          {children}
        </Col>
      </Card>
    </GridItem>
  );
};

export default BaseAvatarCard;
