import { GetApiParams, PostApiParams } from 'src/types';
import { Stage } from 'src/hooks';
import { getAuthorizationTokenFromStorage } from 'src/utils';

/**
 * HTTP error class
 */
export class HttpError extends Error {
  public statusCode: number;
  public message: string;

  constructor(statusCode: number, message: string) {
    super();
    this.statusCode = statusCode;
    this.message = message;
  }
}

const HTTPS_PREFIX = 'https://';
/**
 * A method to get the host from env variables.
 * @returns api url for a stage.
 */
function getHost(): string {
  return HTTPS_PREFIX + 'api.beta.policy-advisor.pxt.amazon.dev';
}

/**
 * Fetch data method.
 * @param params
 * @param currentStage
 */
export const getData = async <T>(params: GetApiParams): Promise<T> => {
  const method: string = 'GET';
  const host = params.host || getHost();
  const headers = {
    ...params.headers,
    Authorization: `Bearer ${getAuthorizationTokenFromStorage()}`,
  };
  let url = `${host}${params.path}`;

  if (params.queryParams) {
    const queryString = new URLSearchParams(params.queryParams).toString();
    url += `?${queryString}`;
  }

  const response = await fetch(url, {
    method,
    headers,
  } as RequestInit);

  if (!response.ok) {
    throw new HttpError(response.status, await response.text());
  }

  return response.json();
};

export const postData =
  () =>
  async (params: PostApiParams<unknown>): Promise<unknown> => {
    const method: string = params.method || 'POST';
    const host = params.host || getHost();
    const headers = {
      ...params.headers,
      Authorization: `Bearer ${getAuthorizationTokenFromStorage()}`,
    };

    const response = await fetch(`${host}${params.path}`, {
      method,
      headers,
      body: JSON.stringify(params.body),
    } as RequestInit);

    if (!response.ok) {
      throw new HttpError(response.status, await response.text());
    }

    return response.json();
  };

/**
 * Fetch data from pre-signed URL.
 *
 * <b>
 * Note : Keeping it separate to allow supporting
 * and parsing other file types in the future.
 * </b>
 * @param preSignedUrl
 */
export const fetchDataFromPreSignedUrl = async (preSignedUrl: string): Promise<string> => {
  const response = await fetch(preSignedUrl);
  if (!response.ok) {
    throw new HttpError(response.status, await response.text());
  }
  return response.text();
};
