import React, { useState } from 'react';
import { Col, Row } from '@amzn/stencil-react-components/layout';
import { H5 } from '@amzn/stencil-react-components/text';
import { ToggleSwitch } from '@amzn/stencil-react-components/toggle-switch';
import { PolicyDiffViewerComponent } from 'src/components';
import EditorViewer from 'src/components/shared/policy-editor/EditorViewer';
import { convertTextToHtml } from 'src/utils/textEditorUtils';

interface PolicyEditorComponentProps {
  isReadOnlyMode: boolean;
  executionResult: {
    updatedPolicy: string;
    currentPolicy: string;
  };
  policyContentUpdateCallback?: (updatedPolicy: string) => void;
}

const PolicyEditorComponent: React.FC<PolicyEditorComponentProps> = ({
  isReadOnlyMode,
  executionResult,
  policyContentUpdateCallback,
}) => {
  const [togglePolicyDiff, setTogglePolicyDiff] = useState<boolean>(false);
  const renderPolicyDetailTab = () => {
    if (togglePolicyDiff) {
      return (
        <PolicyDiffViewerComponent
          oldHtmlData={executionResult.currentPolicy}
          newHtmlData={executionResult.updatedPolicy}
        />
      );
    }
    return (
      <EditorViewer
        isReadOnlyMode={isReadOnlyMode}
        policyContent={convertTextToHtml(executionResult.updatedPolicy)}
        policyContentUpdateCallback={policyContentUpdateCallback}
      />
    );
  };

  return (
    <Col width={'100%'} minHeight={'800px'}>
      <Row justifyContent={'end'}>
        <Col padding={'S200'}>
          <H5>View policy diff</H5>
        </Col>
        <Col padding="S200">
          <ToggleSwitch checked={togglePolicyDiff} onChange={() => setTogglePolicyDiff(!togglePolicyDiff)} />
        </Col>
      </Row>
      <Row padding={'S100'} justifyContent="center" width={'100%'}>
        {renderPolicyDetailTab()}
      </Row>
    </Col>
  );
};

export default PolicyEditorComponent;
