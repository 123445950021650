import { useMutation } from '@tanstack/react-query';
import { HttpError, postData } from 'src/clients';
import { PostApiParams } from 'src/types';
import { useState } from 'react';
import { PerformApproverActionRequest, PerformApproverActionResponse } from 'src/models';
import { PERFORM_APPROVER_ACTION_API } from 'src/paths';

export interface PerformApproverActionState {
  data: PerformApproverActionResponse;
  loading: boolean;
  error: any;
  retry: () => void;
}

export const usePerformApproverAction = () => {
  const {
    isLoading: loading,
    error,
    data: responseData,
    mutate,
  } = useMutation<unknown, HttpError, PostApiParams<unknown>>(postData());
  const [retryParams, setRetryParams] = useState<PerformApproverActionRequest | undefined>(undefined);
  const path = PERFORM_APPROVER_ACTION_API();
  const reviewerAction = (reviewerActionRequest: PerformApproverActionRequest) => {
    setRetryParams(undefined);
    mutate(
      {
        path,
        method: 'POST',
        body: reviewerActionRequest,
      },
      {
        onError: (err) => {
          setRetryParams({ ...reviewerActionRequest });
        },
      },
    );
  };

  const retry = () => {
    if (!retryParams || loading) return;
    reviewerAction(retryParams);
  };

  return {
    reviewerActionState: {
      data: responseData as PerformApproverActionResponse,
      loading,
      error: retryParams ? error : null,
      retry,
    },
    reviewerAction,
  };
};
