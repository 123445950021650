import { User } from 'src/models/api-models/createReview';
import { ApprovalLevelOutput } from 'src/models/api-models/getReview';

/**
 * For other supported Roles check Amazon approval roles enum.
 *
 * <a href="https://tiny.amazon.com/fzalb8w9/codeamazpackDaenblobdf5asrc">Roles</a>
 */
export enum ActorRole {
  REQUESTER = 'REQUESTER',
  APPROVER = 'APPROVER',
}

export enum ReviewStatus {
  PENDING = 'PENDING',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  CANCELLED = 'CANCELLED',
  INVALIDATED = 'INVALIDATED',
  APPROVED_WITH_CONDITION = 'APPROVED_WITH_CONDITION',
}

export interface GetReviewsRequest {
  actorRole: ActorRole;
  reviewStatus?: ReviewStatus[];
  approverReviewAssignmentStatus?: ReviewStatus[];
}

export interface ReviewDetail {
  reviewId: string;
  executionId: string;
  executionVersion: number;
  requesterAlias: string;
  title: string;
  content?: string;
  needByDate: string;
  reviewStatus: ReviewStatus;
  createdAt: string;
  updatedAt?: string;
  approvalPlanVersion?: number;
  referenceId?: string;
  approvalPlan?: ApprovalLevelOutput[];
}

export interface UserApproval {
  approver: User;
  approvalStatus: ReviewStatus;
}

export interface GetReviewsResponse {
  reviews: ReviewDetail[];
}
