import { HttpError, postData } from 'src/clients';
import { useMutation } from '@tanstack/react-query';
import { PostApiParams } from 'src/types';
import { useState } from 'react';
import { CreatePolicyMetadataRequest, CreatePolicyMetadataResponse } from 'src/models';
import { CREATE_POLICY_METADATA_API } from 'src/paths';

export const useCreatePolicyMetadata = () => {
  const {
    isLoading: loading,
    error,
    data: responseData,
    mutate,
  } = useMutation<unknown, HttpError, PostApiParams<unknown>>(postData());
  const [retryParams, setRetryParams] = useState<CreatePolicyMetadataRequest | undefined>(undefined);
  const path = CREATE_POLICY_METADATA_API();
  const createPolicyMetadata = (createPolicyMetadataRequest: CreatePolicyMetadataRequest) => {
    setRetryParams(undefined);
    mutate(
      {
        path,
        method: 'POST',
        body: createPolicyMetadataRequest,
      },
      {
        onError: (err) => {
          setRetryParams({ ...createPolicyMetadataRequest });
        },
      },
    );
  };

  const retry = () => {
    if (!retryParams || loading) return;
    createPolicyMetadata(retryParams);
  };

  return {
    createPolicyMetadataState: {
      data: responseData as CreatePolicyMetadataResponse,
      loading,
      error: retryParams ? error : null,
      retry,
    },
    createPolicyMetadata,
  };
};
