import React from 'react';
import { Col, GridContainer, Row } from '@amzn/stencil-react-components/layout';
import { H3 } from '@amzn/stencil-react-components/text';
import { Card } from '@amzn/stencil-react-components/card';

const LevelBasedReviewersCard: React.FC<{ levelName: string; children: React.ReactNode; isCardView?: boolean }> = ({
  levelName,
  children,
  isCardView = true,
}) => {
  const renderCards = () => (
    <Col width={'100%'}>
      <Row>
        <H3>{levelName}</H3>
      </Row>
      <Row padding={{ top: 'S400', bottom: 'S400' }} width={'100%'}>
        <GridContainer>{children}</GridContainer>
      </Row>
    </Col>
  );
  if (isCardView) {
    return (
      <Card padding={'S500'} width={'100%'} border={'0'}>
        {renderCards()}
      </Card>
    );
  }
  return <>{renderCards()}</>;
};

export default LevelBasedReviewersCard;
