import React from 'react';
import { SearchField } from '@amzn/stencil-react-components/search';

const SearchBoxComponent = () => {
  return (
    <SearchField
      placeholder="Search Policy Name"
      noResultsText="No policies found"
      width={'25vw'}
      results={[]}
      onChange={() => {}}
      resultToString={(result) => result.key}
      query={''}
    />
  );
};

export default SearchBoxComponent;
