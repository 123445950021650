import { useQuery, UseQueryResult } from '@tanstack/react-query';
import { getData } from 'src/clients';
import { PolicyExecutionResponse } from 'src/models';
import { GET_EXECUTION_API } from 'src/paths';

export const useGetExecution = ({
  executionId,
  version,
}: {
  executionId?: string;
  version?: number;
}): UseQueryResult<PolicyExecutionResponse> => {
  const path = GET_EXECUTION_API(executionId, version);
  return useQuery<unknown, unknown, PolicyExecutionResponse>(
    ['executionId', executionId, version],
    () =>
      getData<PolicyExecutionResponse>({
        path,
      }),
    {
      // Post response data transformer.
      select: (data: any) => {
        return { ...data, createdAt: new Date(data?.createdAt), updatedAt: new Date(data?.updatedAt) };
      },
      enabled: !!executionId,
      staleTime: 1000 * 60, // Caching for 1 min
    },
  );
};
