import { useEffect, useState } from 'react';
import { PolicyContentData, usePolicyDetails, UsePolicyDetailsResult } from 'src/hooks';
import { ComparisonGroupType } from 'src/models';
import { Nullable } from 'src/types';

export interface UseDetailsPageProps {
  executionId: string;
  version: number;
}

export interface UseDetailsPageResult extends UsePolicyDetailsResult {
  compareOptions: string;
  setCompareOptions: (compareOption: string) => void;
  groupBasedPoliciesMap: Nullable<Record<ComparisonGroupType, PolicyContentData[]>>;
}

export const useDetailsPage = ({ executionId, version }: UseDetailsPageProps): UseDetailsPageResult => {
  // TODO : Remove empty string once the executionId is made required.
  const policyDetailsResult = usePolicyDetails(executionId, version);
  // TODO : Add other relevant hooks such Policy MetaData to get the domain, country, etc around policy

  const [groupBasedPoliciesMap, setGroupBasedPoliciesMap] =
    useState<Record<ComparisonGroupType, PolicyContentData[]>>();

  const [compareOptions, setCompareOptions] = useState<string>('');

  /**
   * Segregate the policy content data on the basis of ComparisonGroupType.
   *
   * @param data - PolicyContentData
   */
  const segregateByGroup = (data: PolicyContentData[]): Record<ComparisonGroupType, PolicyContentData[]> => {
    return data.reduce((groupBasedSegregation, policyContent) => {
      if (!groupBasedSegregation[policyContent.group]) {
        groupBasedSegregation[policyContent.group] = [];
      }
      groupBasedSegregation[policyContent.group].push(policyContent);

      return groupBasedSegregation;
    }, {} as Record<ComparisonGroupType, PolicyContentData[]>);
  };

  useEffect(() => {
    if (policyDetailsResult.responseState.policyContentData) {
      const segregatedResult = segregateByGroup(policyDetailsResult.responseState.policyContentData);
      setGroupBasedPoliciesMap(segregatedResult);
    }
  }, [policyDetailsResult.responseState.policyContentData.length]);

  return {
    compareOptions,
    setCompareOptions,
    ...policyDetailsResult,
    groupBasedPoliciesMap,
  };
};
