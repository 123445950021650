import { StencilThemeColors } from '@amzn/stencil-react-theme-default';
import { ReviewStatus } from 'src/models';
import { ExecutionStatus } from 'src/models/api-models/getExecutions';

export const getBorderColor = (status: string): keyof StencilThemeColors => {
  switch (status) {
    case ExecutionStatus.POLICY_COLLECTION_NOT_STARTED:
    case ExecutionStatus.COMPARISON_NOT_STARTED:
    case ExecutionStatus.DRAFT:
    case ExecutionStatus.CANCELLED:
    case ReviewStatus.CANCELLED:
      return 'neutral70';

    case ExecutionStatus.POLICY_COLLECTION_IN_PROGRESS:
    case ExecutionStatus.COMPARISON_IN_PROGRESS:
    case ExecutionStatus.IN_REVIEW:
    case ReviewStatus.PENDING:
      return 'yellow70';

    case ExecutionStatus.POLICY_COLLECTION_COMPLETED:
    case ExecutionStatus.COMPARISON_COMPLETED:
    case ExecutionStatus.APPROVED:
    case ReviewStatus.APPROVED:
      return 'green70';

    case ExecutionStatus.POLICY_COLLECTION_FAILED:
    case ExecutionStatus.COMPARISON_FAILED:
    case ExecutionStatus.REJECTED:
    case ReviewStatus.REJECTED:
    case ReviewStatus.INVALIDATED:
      return 'red70';

    case ExecutionStatus.REWORK:
      return 'primary70';

    case ReviewStatus.APPROVED_WITH_CONDITION:
      return 'green60';

    default:
      return 'primary70';
  }
};

export const compare = (a: any, b: any, activeSortId: any) => {
  // This requires the sort IDs to be the same as the property
  // names in the data object. If they aren't, some additional
  // logic would be required to extract the data for the column.
  const firstValue = a[activeSortId as keyof any];
  const secondValue = b[activeSortId as keyof any];

  return firstValue < secondValue ? -1 : 1;
};
