import React from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import NavigationBarComponent from 'src/components/navbar/NavigationBarComponent';
import {
  DASHBOARD,
  POLICY_DETAILS,
  REVIEW_SUBMISSION_WORKFLOW_PATH,
  POLICY_REVIEW_WORKFLOW_PATH,
  CREATE_EXECUTION,
  EXECUTION_ID_VERSION_SUFFIX,
} from 'src/constants';
import Dashboard from 'src/components/dashboard/Dashboard';
import { DetailsPage, PolicyReviewWorkflow, ReviewSubmissionWorkflow } from 'src/components';
import { PostAuthenticationController, useAuthentication } from './Authentication';
import { CreateExecutionWorkflow } from './create-execution';

export const App = () => {
  const { authenticatedUser, currentUser } = useAuthentication();
  const userName = authenticatedUser?.userName || '';
  return (
    <>
      <Router basename="/">
        <PostAuthenticationController>
          <NavigationBarComponent userName={userName} />
          <Routes>
            <Route path={DASHBOARD} element={<Dashboard />} />
            <Route
              path={`${REVIEW_SUBMISSION_WORKFLOW_PATH}${EXECUTION_ID_VERSION_SUFFIX}`}
              element={<ReviewSubmissionWorkflow />}
            />
            <Route
              path={`${POLICY_REVIEW_WORKFLOW_PATH}${EXECUTION_ID_VERSION_SUFFIX}`}
              element={<PolicyReviewWorkflow />}
            />
            <Route path={`${POLICY_DETAILS}${EXECUTION_ID_VERSION_SUFFIX}`} element={<DetailsPage />} />
            <Route path={CREATE_EXECUTION} element={<CreateExecutionWorkflow />} />
            <Route path={`${CREATE_EXECUTION}${EXECUTION_ID_VERSION_SUFFIX}`} element={<CreateExecutionWorkflow />} />
          </Routes>
        </PostAuthenticationController>
      </Router>
    </>
  );
};
