import React from 'react';
import { Col, Row } from '@amzn/stencil-react-components/layout';
import { RichTextEditor } from '@amzn/stencil-react-components/rte';
import { Button, ButtonIconPosition, ButtonVariant } from '@amzn/stencil-react-components/button';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { IconDownload } from '@amzn/stencil-react-components/icons';
import { PolicyPdfView, usePolicyReviewWorkflowContext } from 'src/components';
import { ActorRole } from 'src/models';
import { useNavigate } from 'react-router-dom';

const PolicyPublishAction = () => {
  const { policyContentDataItems, actorRole } = usePolicyReviewWorkflowContext();
  const navigate = useNavigate();
  return (
    <Col gridGap={'S300'}>
      {policyContentDataItems
        .filter((policyContentData) => policyContentData.editedContent)
        .map((policyContentData) => (
          <>
            <RichTextEditor
              maxHeight={'450px'}
              defaultContent={`<p>${
                policyContentData.editedContent ? policyContentData.editedContent.replace(/\n/g, '<br/>') : ''
              }</p>`}
              disabled
            />
            <Row width={'100%'} justifyContent={'flex-end'} gridGap={'S400'}>
              <PDFDownloadLink
                fileName={`${policyContentData.policyName}.pdf`}
                document={
                  <PolicyPdfView
                    policy={policyContentData.editedContent || ''}
                    policyName={policyContentData.policyName}
                  />
                }
              >
                <Button
                  variant={ButtonVariant.Secondary}
                  icon={<IconDownload />}
                  iconPosition={ButtonIconPosition.Leading}
                  disabled={actorRole === ActorRole.APPROVER} // Disabled download for based on actor role.
                >
                  Download as PDF
                </Button>
              </PDFDownloadLink>
              <Button variant={ButtonVariant.Primary} onClick={() => navigate('/')}>
                Home
              </Button>
            </Row>
          </>
        ))}
    </Col>
  );
};

export default PolicyPublishAction;
